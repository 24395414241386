import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import { ReactTable } from '@components';
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import CreateAccount from './CreateAccount';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Edit from './Edit';
import { apiGetAccount, apiGetUsersForAccount, apiUpdateAccount } from '@store';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ELECTRICITY_PATH, GAS_PATH, COMMON_PATH, showToast } from '@utils';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const Accounts = ({ endpoint, account, isLoading }) => {
    const location = useLocation();
    const dispatch = useDispatch()
    const [selectedAccount, setSelectedAccount] = useState(null)
    const { usersForAccount } = useSelector((state) => state.user)

    // edit account modal
    const [isEditAccountModalOpen, setIsEditAccountModalOpen] = useState(false)
    const openEditAccountModal = (selectedAccount) => {
        setSelectedAccount(selectedAccount)
        setIsEditAccountModalOpen(true)
    }
    const closeEditAccountModal = () => setIsEditAccountModalOpen(false)

    // send uuid from account list to get users for account at the start one by one
    useEffect(() => {
        account?.accounts?.forEach((acc) => {
            dispatch(apiGetUsersForAccount(acc.uuid))
        })
    }, [account, dispatch])

    // take the usersForAccount length and add the data to user field in account
    const data = useMemo(() => {
        return account?.accounts?.map((acc) => {
            const users = usersForAccount?.filter((user) => user?.AccountUUID === acc?.uuid)
            return {
                ...acc,
                users: users.length
            }
        })
    }, [account, usersForAccount])

    const handleDelete = async (row) => {
        window.confirm('Are you sure you want to delete this account?')
        if (true) {
            const data = { ...row, deleted: 1 }
            const resp = await dispatch(apiUpdateAccount(data))
            if (resp?.meta?.requestStatus === 'fulfilled') {
                showToast('info', 'Account deleted successfully')
                dispatch(apiGetAccount())
            } else if (resp?.meta?.requestStatus === 'rejected') {
                showToast('error', resp?.payload || 'Account deletion failed')
            }
        }
    }

    const columns = useMemo(
        () => [
            {
                header: "Account Name",
                id: "company_name",
                accessorFn: ({ company_name }) => company_name || "",
                Cell: ({ renderedCellValue }) =>
                    <Typography
                        sx={{
                            color: '#3B9D9D',
                            fontSize: 'inherit',
                            fontWeight: 'bold',
                            pl: '8px'
                        }}
                    >
                        {renderedCellValue}
                    </Typography>,
                size: 50
            },
            {
                header: "Short Name",
                id: "short_name",
                accessorFn: ({ short_name }) => short_name || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Email",
                id: "email",
                accessorFn: ({ email }) => email || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Account Users",
                id: "users",
                Cell: ({ row }) =>
                    <Link
                        to={
                            location.pathname.includes('gas') ?
                                `${GAS_PATH.USER_MANAGEMENT}/${row?.original?.uuid}` :
                                location.pathname.includes('electricity') ?
                                    `${ELECTRICITY_PATH.USER_MANAGEMENT}/${row?.original?.uuid}` :
                                    `${COMMON_PATH.USER_MANAGEMENT}/${row?.original?.uuid}`
                        }
                        style={{
                            textDecoration: 'underline',
                            color: 'blue',
                        }}
                    >
                        Users
                    </Link>
                ,
                size: 50
            },
            {
                header: "Status",
                id: "status",
                Cell: ({ row }) => <>
                    {
                        !row?.original?.disabled ?
                            <Box className='bg-green-100 text-green-800 text-sm font-medium me-2 py-0.5 text-center rounded'>Active</Box> :
                            <Box className='bg-red-100 text-red-800 text-sm font-medium me-2 py-0.5 text-center rounded'>Inactive</Box>
                    }
                </>,
                size: 50
            }
        ], [location.pathname]
    );

    const actionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <Box className='flex gap-4 items-center justify-center' key={'buttons'}>
                        <Button
                            variant='outlined'
                            color='primary'
                            sx={{
                                p: 0,
                                m: 0,
                                borderColor: '1px solid #27A9FF',
                                color: '#27A9FF',
                                fontSize: '13px',
                                '&:hover': {
                                    borderColor: '1px solid #27A9FF',
                                    color: 'white',
                                    backgroundColor: '#27A9FF',
                                },
                            }}
                            onClick={() => openEditAccountModal(row.original)}
                        >
                            Edit
                        </Button>
                        <Button
                            variant='outlined'
                            color='error'
                            sx={{
                                p: 0,
                                m: 0,
                                borderColor: 'brown',
                                color: 'brown',
                                fontSize: '13px',
                                '&:hover': {
                                    borderColor: 'brown ',
                                    color: 'white',
                                    backgroundColor: 'brown',
                                },
                            }}
                            onClick={() => handleDelete(row.original)}
                        >
                            Delete
                        </Button>
                    </Box>
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    // create account modal
    const [isCreateAccountModalOpen, setIsCreateAccountModalOpen] = useState(false)
    const openCreateAccountModal = () => setIsCreateAccountModalOpen(true)
    const closeCreateAccountModal = () => setIsCreateAccountModalOpen(false)

    return (
        <>
            <Modal open={isCreateAccountModalOpen} onClose={closeCreateAccountModal}>
                <CreateAccount
                    handleClose={closeCreateAccountModal}
                    open={isCreateAccountModalOpen}
                    endpoint={endpoint}
                />
            </Modal>
            <Modal open={isEditAccountModalOpen} onClose={closeEditAccountModal}>
                <Edit
                    account={selectedAccount}
                    handleClose={closeEditAccountModal}
                    open={isEditAccountModalOpen}
                    endpoint={endpoint}
                />
            </Modal>
            <ReactTable
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <Tooltip title="Export Accounts">
                                <IconButton
                                    onClick={() => {
                                        const header = 'Account Name,Short Name,Email,Account Users,Status'
                                        const data = account?.accounts?.map((acc) => {
                                            return `${acc.company_name},${acc.short_name},${acc.email},${usersForAccount.filter((user) => user?.AccountUUID === acc?.uuid).length},${acc.disabled ? 'Inactive' : 'Active'}`
                                        })
                                        const csv = `${header}\n${data?.join('\n')}`
                                        const blob = new Blob([csv], { type: 'text/csv' })
                                        const url = window.URL.createObjectURL(blob)
                                        const a = document.createElement('a')
                                        a.href = url
                                        a.download = 'accounts.csv'
                                        a.click()
                                    }}
                                >
                                    <ManageAccountsIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Export Users">
                                <IconButton
                                    onClick={() => {
                                        const header = 'company,fullname,email,phone,temporary\n'
                                        const user_data = account?.users?.map((user) => {
                                            return `${user?.company},${user?.fullname},${user?.email},${user?.phone},${user?.temporary}\n`
                                        })
                                        const csv = `${header}\n${user_data?.join('\n')}`
                                        const blob = new Blob([csv], { type: 'text/csv' })
                                        const url = window.URL.createObjectURL(blob)
                                        const a = document.createElement('a')
                                        a.href = url
                                        a.download = 'users.csv'
                                        a.click()
                                    }}
                                >
                                    <FileDownloadIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Create Account'>
                                <IconButton
                                    onClick={openCreateAccountModal}
                                >
                                    <AddBoxIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                }
                headerText={'Accounts'}
                columns={columns}
                data={data || []}
                actions={actionsMenu}
                isLoading={isLoading}
            />
        </>
    )
}

export default Accounts
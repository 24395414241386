import React, { forwardRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CustomModal } from '@components'
import { Box, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import { apiBuyContract, apiGetOrder } from '@store'
import { showToast, getMargin } from '@utils'

export const Buy = forwardRef(({ handleClose, selectedContract, user, sym }, ref) => {
    const dispatch = useDispatch()
    const { hedgeMargin } = useSelector((state) => state.hedgeMargin)

    const [state, setState] = useState({
        market: 'THE',
        type: 'buy',
        price_selected: '',
        seller: '',
        buyer: user?.CompanyShort,
        deliverypoint: 'THE',
        volume: '',
        exitpoint: '',
        price_percent: '100',
        ordertype: 'internal',
        currency: 'CHF',
        sym: selectedContract?.contract || sym,
    })

    const [margin, setMargin] = useState(0)

    useEffect(() => {
        setMargin(getMargin({ contractName: selectedContract?.contract || sym, hedgeMargin, volume: state.volume }))
    }, [selectedContract, sym, hedgeMargin, state.volume])

    useEffect(() => {
        if (user?.CanBuyFrom?.length > 0) {
            setState((prevState) => ({
                ...prevState,
                seller: user.CanBuyFrom[0]
            }));
        }
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const [type, setType] = useState('ask')

    const handleSubmit = async (e) => {
        e.preventDefault()
        const volume = parseFloat(state.volume)
        const ask = parseFloat(selectedContract?.ask)

        const data = {
            ...state,
            margin: margin,
            total_price: margin + ask,
            volume,
            price: { ask: ask },
            exitpoint: state.exitpoint || {},
        }

        const resp = await dispatch(apiBuyContract(data))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            handleClose()
            dispatch(apiGetOrder())
            showToast('success', 'Purchase order succesfully saved')
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp?.payload || 'Error buying contract')
        }
    }

    // timer
    const [timer, setTimer] = useState(25);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer => timer - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [timer]);

    useEffect(() => {
        if (timer === 0) {
            handleClose();
        }
    }, [timer, handleClose]);

    useEffect(() => {
        if (ref?.current) {
            setTimer(25);
        }
    }, [ref]);

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Buy Contract`}
            buttonText={'Buy'}
            timer={timer}
            disabled={state.volume ? false : true}
            ref={ref}
        >
            <Box className='flex flex-col gap-3'>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                        shrink
                    >
                        Please Select what you would like to buy
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Type"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                    >
                        <MenuItem value={'ask'}>Ask Price</MenuItem>
                    </Select>
                </FormControl>
                <Box className='flex gap-3'>
                    <Typography>Contract:</Typography>
                    <Typography color={'black'}>{selectedContract?.contract || sym}</Typography>
                </Box>
                <FormControl fullWidth variant="outlined">
                    <InputLabel
                        id="contractLabel"
                        sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                    >
                        Volume
                    </InputLabel>
                    <OutlinedInput
                        id="volume"
                        label="Volume"
                        name="volume"
                        required
                        type="number"
                        onChange={handleChange}
                        value={state.volume}
                        inputProps={{ step: 0.01 }}
                        endAdornment={
                            <InputAdornment position="start" sx={{ ml: 2 }}>
                                MW
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                    >
                        Endpoint
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Endpoint"
                        name="exitpoint"
                        value={state.exitpoint}
                        onChange={handleChange}
                    >
                        {user?.Endpoints?.map((endpoint, index) => (
                            <MenuItem key={index} value={endpoint}>{endpoint.name} - {endpoint.location}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                    >
                        Seller
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Seller"
                        name='seller'
                        value={state.seller}
                        onChange={handleChange}
                    >
                        {user?.CanBuyFrom?.map((seller, index) => (
                            <MenuItem key={index} value={seller}>
                                {seller}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box className='flex gap-3'>
                    <Typography>Price:</Typography>
                    <Typography color={'black'}>{selectedContract?.ask}</Typography>
                </Box>
                <Box className='flex gap-3'>
                    <Typography>Margin:</Typography>
                    <Typography color={'black'}>{margin || 0} </Typography >
                </Box>
                <Box className='flex gap-3'>
                    <Typography>Total Price:</Typography>
                    <Typography color={'black'}>{margin + parseFloat(selectedContract?.ask)} </Typography >
                </Box>
                <Box className='flex gap-3'>
                    <Typography>Contract Hours:</Typography>
                    <Typography color={'black'}>{selectedContract?.contract_hours} </Typography >
                </Box>
                <Box className='flex gap-3'>
                    <Typography>Notional Value:</Typography>
                    <Typography color={'black'}> {state?.volume ? (selectedContract?.contract_hours * (parseFloat(selectedContract?.ask) + margin) * parseFloat(state.volume)).toFixed(3) : 0} </Typography >
                </Box>
                <Box className='flex gap-3'>
                    <Typography>Last Updated:</Typography>
                    <Typography color={'black'}>{selectedContract?.updated_at_cet_str}</Typography>
                </Box>
            </Box>
        </CustomModal>
    )
})
import React, { useCallback, useMemo, useState } from 'react'
import { Box, Button, IconButton, Modal, Tooltip } from '@mui/material'
import { ReactTable } from '@components'
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import AddBoxIcon from '@mui/icons-material/AddBox';
import CreateProfile from './CreateProfile';
import { useDispatch, useSelector } from 'react-redux';
import { apiDeleteTheProfile, apiGetSetPriceProfile } from '@store';
import { showToast } from '@utils';
import Buy from './Buy';

const Profiles = () => {
    const dispatch = useDispatch();
    const [selectedProfile, setSelectedProfile] = useState();
    const { user } = useSelector((state) => state.user);

    const {
        theeur,
        setPriceProfile,
        isGetSetPriceProfileLoading,
        disableTrading
    } = useSelector(state => state.the);

    const disableTradingStatus = disableTrading?.disable;

    // open modal
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = (singlePriceProfile) => {
        setOpenModal(true);
        setSelectedProfile(singlePriceProfile);
    };
    const handleCloseModal = () => { setOpenModal(false); };

    // add profile modal
    const [openAddProfileModal, setOpenAddProfileModal] = useState(false);
    const handleOpenAddProfileModal = () => { setOpenAddProfileModal(true); };
    const handleCloseAddProfileModal = () => { setOpenAddProfileModal(false); };

    const columns = useMemo(
        () => [
            {
                header: "Profile Name",
                id: "name",
                accessorFn: ({ name }) => name || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Created At",
                id: "created_at",
                accessorFn: ({ created_at }) => created_at || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            }
        ], []
    );

    // ask window to delete profile
    const handleDeleteProfile = useCallback(async (profile) => {
        if (window.confirm(`Are you sure you want to delete ${profile.name} profile?`)) {
            const resp = await dispatch(apiDeleteTheProfile(profile));
            if (resp?.meta?.requestStatus === 'fulfilled') {
                showToast('info', 'Profile deleted successfully');
                dispatch(apiGetSetPriceProfile())
            } else if (resp?.meta?.requestStatus === 'rejected') {
                showToast('error', resp.payload || 'Failed to delete profile');
            }
        }
    }, [dispatch])

    const actionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <Box className='flex gap-4 items-center justify-center' key='buttons'>
                        {disableTradingStatus === false && user?.HideBuyButton === false &&
                            <Button
                                variant='outlined'
                                color='primary'
                                sx={{
                                    p: 0,
                                    m: 0,
                                    borderColor: '1px solid #27A9FF',
                                    color: '#27A9FF',
                                    fontSize: '13px',
                                    '&:hover': {
                                        borderColor: '1px solid #27A9FF',
                                        color: 'white',
                                        backgroundColor: '#27A9FF',
                                    },
                                }}
                                onClick={() => {
                                    handleOpenModal();
                                    setSelectedProfile(row?.original)
                                }}
                            >
                                Buy
                            </Button>
                        }
                        <Button
                            variant='outlined'
                            color='error'
                            sx={{
                                p: 0,
                                m: 0,
                                borderColor: 'brown',
                                color: 'brown',
                                fontSize: '13px',
                                '&:hover': {
                                    borderColor: 'brown',
                                    color: 'white',
                                    backgroundColor: 'brown',
                                },
                            }}
                            onClick={() => handleDeleteProfile(row?.original)}
                        >
                            Delete
                        </Button>
                    </Box>
                ]
        , [disableTradingStatus, user, handleDeleteProfile]
    );

    return (
        <Box>
            <Modal open={openModal} onClose={handleCloseModal}>
                <Buy
                    handleClose={handleCloseModal}
                    open={openModal}
                    singlePriceProfile={selectedProfile}
                />
            </Modal>
            <Modal open={openAddProfileModal} onClose={handleCloseAddProfileModal}>
                <CreateProfile
                    handleClose={handleCloseAddProfileModal}
                    open={openAddProfileModal}
                    eur={theeur}
                    user={user}
                />
            </Modal>
            <ReactTable
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <Tooltip title='Create Profile'>
                                <IconButton
                                    onClick={handleOpenAddProfileModal}
                                >
                                    <AddBoxIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                }
                headerText={'Profiles'}
                columns={columns}
                data={setPriceProfile || []}
                actions={actionsMenu}
                isLoading={isGetSetPriceProfileLoading}
            />
        </Box>
    )
}

export default Profiles
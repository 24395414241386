import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { AppName, ContractDetailsTitle } from '@utils';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Ask from './ask/Ask';
import Bid from './bid/Bid';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ContractDetails() {

    useEffect(() => {
        document.title = `${AppName} | ${ContractDetailsTitle}`
    }, [])

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    sx={{ minHeight: '32px' }}  // Reduces the height of the Tabs
                >
                    <Tab
                        label="Ask"
                        {...a11yProps(0)}
                        sx={{ fontSize: '0.875rem', px: 1, minHeight: '32px' }}  // Smaller font size and padding
                    />
                    <Tab
                        label="Bid"
                        {...a11yProps(1)}
                        sx={{ fontSize: '0.875rem', px: 1, minHeight: '32px' }}  // Smaller font size and padding
                    />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <Ask />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Bid />
            </CustomTabPanel>
        </Box>
    );
}

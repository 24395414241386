const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]

const getContractType = (contractName) => {
    let contractType = "normal"

    // normal, monthly, quarterly, seasonal, cal

    // Check if contract is monthly
    for (let i = 0; i < months.length; i++) {
        if (contractName.includes(months[i])) {
            contractType = "monthly"
            break
        }
    }

    // Check if contract is quarterly; if first letter is Q
    if (contractName[0] === "Q") {
        contractType = "quarterly"
    }

    // Check if contract is seasonal; if contains SUMMER or WINTER
    if (contractName.includes("SUMMER") || contractName.includes("WINTER")) {
        contractType = "seasonal"
    }

    // Check if contract is calendar; if contains CAL
    if (contractName.includes("CAL")) {
        contractType = "cal"
    }

    return contractType
}

const getMargin = ({ contractName, hedgeMargin, volume }) => {
    const contractType = getContractType(contractName)

    let margin = 0

    if (contractType === "normal") {
        const conditionMargin = hedgeMargin.find((item) => item.condition === "When volume on any contract is less than")
        if (volume < conditionMargin?.mw) {
            margin = conditionMargin?.margin || 0
        }
    } else if (contractType === "monthly") {
        const conditionMargin = hedgeMargin.find((item) => item.condition === "When Months are more than")
        if (volume > conditionMargin?.mw) {
            margin = conditionMargin?.margin || 0
        }
    } else if (contractType === "quarterly") {
        const conditionMargin = hedgeMargin.find((item) => item.condition === "When Quarter is more than")
        if (volume > conditionMargin?.mw) {
            margin = conditionMargin?.margin || 0
        }
    } else if (contractType === "seasonal") {
        const conditionMargin = hedgeMargin.find((item) => item.condition === "When seasons are more than")
        if (volume > conditionMargin?.mw) {
            margin = conditionMargin?.margin || 0
        }
    } else if (contractType === "cal") {
        const conditionMargin = hedgeMargin.find((item) => item.condition === "When Calendars are more than")
        if (volume > conditionMargin?.mw) {
            margin = conditionMargin?.margin || 0
        }
    }

    return margin
}

const isHighlighted = (row) => {
    if (row?.original?.bid > row?.original?.ask) {
        return true;
    }
    return false;
}

export { getContractType, getMargin, isHighlighted };
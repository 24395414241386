import { useDispatch, useSelector } from "react-redux"
import { TabComponent } from "@components"
import Mp from "./marketplace/Mp";
import Biogas from "./biogas/Biogas";
import { useEffect, useState } from "react";
import { AppName, MarketPlaceTitle } from "@utils";
import { apiGetMarketplace, apiGetBiogas } from "@store";

const Marketplace = () => {

    useEffect(() => {
        document.title = `${AppName} | ${MarketPlaceTitle}`
    }, [])

    const dispatch = useDispatch()
    const { marketplace, isGetMarketplaceLoading, biogas, isGetBiogasLoading } = useSelector(state => state.marketplace);

    useEffect(() => {
        dispatch(apiGetMarketplace())
        dispatch(apiGetBiogas())
    }, [dispatch])

    // open entertrade modal for marketplace
    const [enterTradeMpModal, setEnterTradeMpModal] = useState(false)
    const handleOpenModal = () => { setEnterTradeMpModal(true); }
    const handleCloseModal = () => { setEnterTradeMpModal(false); }

    // open entertrade modal for biogas
    const [enterTradeBiogasModal, setEnterTradeBiogasModal] = useState(false)
    const handleOpenModalBiogas = () => { setEnterTradeBiogasModal(true); }
    const handleCloseModalBiogas = () => { setEnterTradeBiogasModal(false); }

    const tabs = [
        {
            name: 'MarketPlace',
            content: <Mp marketplace={marketplace} isLoading={isGetMarketplaceLoading} openModal={enterTradeMpModal} handleCloseModal={handleCloseModal} />,
            buttons: [
                {
                    text: 'Enter Your Trade',
                    backgroundColor: 'blue',
                    color: 'white',
                    onClick: () => {
                        handleOpenModal()
                    }
                }
            ]
        }, {
            name: 'BioGas',
            content: <Biogas biogas={biogas} isLoading={isGetBiogasLoading} openModal={enterTradeBiogasModal} handleCloseModal={handleCloseModalBiogas} />,
            buttons: [
                {
                    text: 'Enter Your Trade',
                    backgroundColor: 'blue',
                    color: 'white',
                    onClick: () => {
                        handleOpenModalBiogas()
                    }
                }
            ]
        }
    ]

    return (
        <TabComponent tabs={tabs} />
    )
}

export default Marketplace
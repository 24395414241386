import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    hedgeMargin: [],
    isGetHedgeMarginLoading: false,
}

export const apiGetHedgeMargin = createAsyncThunk(
    "hedgeMargin/getHedgeMargin",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/buy_sell_margin');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiAddHedgeMargin = createAsyncThunk(
    "hedgeMargin/addHedgeMargin",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/buy_sell_margin', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const hedgeMarginSlice = createSlice({
    name: "hedgeMargin",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get Hedge Margin
        builder
            .addCase(apiGetHedgeMargin.pending, (state) => {
                state.isGetHedgeMarginLoading = true;
            })
            .addCase(apiGetHedgeMargin.fulfilled, (state, action) => {
                state.hedgeMargin = action.payload.data;
                state.isGetHedgeMarginLoading = false;
            })
            .addCase(apiGetHedgeMargin.rejected, (state) => {
                state.isGetHedgeMarginLoading = false;
            });

        // Add Hedge Margin
        builder
            .addCase(apiAddHedgeMargin.pending, (state) => {
                state.isGetHedgeMarginLoading = true;
            })
            .addCase(apiAddHedgeMargin.fulfilled, (state, action) => {
                state.hedgeMargin = action.payload.data;
                state.isGetHedgeMarginLoading = false;
            })
            .addCase(apiAddHedgeMargin.rejected, (state) => {
                state.isGetHedgeMarginLoading = false;
            });
    }
});

export default hedgeMarginSlice.reducer;
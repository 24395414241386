import React, { useMemo } from 'react'
import { ReactTable } from '@components';
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import { Box, IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

const tooltipText = ` In forecasting models, quantiles can be used to provide a range of predicted values. Instead of just giving a single point estimate, the model can output several quantile predictions to represent different levels of uncertainty. For example, you might have:
    0.1 quantile: The value below which 10% of the data points fall.
    0.5 quantile (median): The middle value of the predicted distribution.
    0.9 quantile: The value below which 90% of the data points fall.`;

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 400,
    },
});

const TimeSeriesForecasting = ({ ncgDataPoints }) => {
    const bidForecastDataColumn = useMemo(
        () => [
            {
                header: "Date",
                id: "date_str",
                accessorFn: ({ date_str }) => date_str || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "10th percentile",
                id: "time_series_forecasting1",
                Cell: ({ row }) => <>{row?.original?.bid_forecast?.timesfm_q_1?.toFixed(3)}</>,
                size: 50
            },
            {
                header: "20th percentile",
                id: "time_series_forecasting2",
                Cell: ({ row }) => <>{row?.original?.bid_forecast?.timesfm_q_2?.toFixed(3)}</>,
                size: 50
            },
            {
                header: "30th percentile",
                id: "time_series_forecasting3",
                Cell: ({ row }) => <>{row?.original?.bid_forecast?.timesfm_q_3?.toFixed(3)}</>,
                size: 50
            },
            {
                header: "40th percentile",
                id: "time_series_forecasting4",
                Cell: ({ row }) => <>{row?.original?.bid_forecast?.timesfm_q_4?.toFixed(3)}</>,
                size: 50
            },
            {
                header: "50th percentile",
                id: "time_series_forecasting5",
                Cell: ({ row }) => <>{row?.original?.bid_forecast?.timesfm_q_5?.toFixed(3)}</>,
                size: 50
            },
            {
                header: "60th percentile",
                id: "time_series_forecasting6",
                Cell: ({ row }) => <>{row?.original?.bid_forecast?.timesfm_q_6?.toFixed(3)}</>,
                size: 50
            },
            {
                header: "70th percentile",
                id: "time_series_forecasting7",
                Cell: ({ row }) => <>{row?.original?.bid_forecast?.timesfm_q_7?.toFixed(3)}</>,
                size: 50
            },
            {
                header: "80th percentile",
                id: "time_series_forecasting8",
                Cell: ({ row }) => <>{row?.original?.bid_forecast?.timesfm_q_8?.toFixed(3)}</>,
                size: 50
            },
            {
                header: "90th percentile",
                id: "time_series_forecasting9",
                Cell: ({ row }) => <>{row?.original?.bid_forecast?.timesfm_q_9?.toFixed(3)}</>,
                size: 50
            },
        ], []
    );

    return (
        <ReactTable
            renderToolbarInternalActions={
                ({ table }) => (
                    <div onMouseDown={(e) => e.stopPropagation()}>
                        <MRT_ToggleGlobalFilterButton table={table} />
                        <CustomWidthTooltip title={
                            <Box style={{ whiteSpace: 'pre-line' }}>
                                {tooltipText}
                            </Box>
                        }>
                            <IconButton>
                                <InfoOutlinedIcon />
                            </IconButton>
                        </CustomWidthTooltip>
                    </div>
                )
            }
            headerText={`Time Series Forecasting`}
            columns={bidForecastDataColumn}
            data={ncgDataPoints?.forecast_data || []}
        />
    )
}

export default TimeSeriesForecasting
import { Box, Button, Modal, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactTable } from '@components';
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import Buy from './Buy';
import Sell from './Sell';
import { AppName, IntradayTitle } from '@utils';
import { apiGetIntraday } from '@store'

// const dataForBid = marketplace.map((item) => {
//     return item?.bids?.map((bid) => {
//         return bid
//     }) || {}
// }).flat()

// const dataForAsk = marketplace.map((item) => {
//     return item?.asks?.map((ask) => {
//         return ask
//     }
//     ) || {}
// }).flat()

const Intraday = () => {
    useEffect(() => {
        document.title = `${AppName} | ${IntradayTitle}`
    }, [])

    const dispatch = useDispatch()

    const { intraday } = useSelector(state => state.intraday)
    const { user } = useSelector(state => state.user)

    useEffect(() => {
        dispatch(apiGetIntraday())
    }, [dispatch])

    const dataForAsk = useMemo(
        () => intraday.filter(row => row.Type === 'Ask'),
        [intraday]
    );

    const dataForBuy = useMemo(
        () => intraday.filter(row => row.Type === 'Bid'),
        [intraday]
    );

    const [selectedContract, setSelectedContract] = useState(null)

    // sell modal
    const [openSellModal, setOpenSellModal] = useState(false)
    const handleOpenSellModal = (selectedContract) => {
        setSelectedContract(selectedContract)
        setOpenSellModal(true)
    }
    const handleCloseSellModal = () => setOpenSellModal(false)

    const columnsForAsk = useMemo(
        () => [
            {
                header: "Contract",
                id: "Contract",
                accessorFn: ({ Contract }) => Contract || "",
                Cell: ({ renderedCellValue }) => <Typography sx={{ color: '#3B9D9D', fontSize: 'inherit', fontWeight: 'bold', pl: '8px' }}>{renderedCellValue}</Typography>,
                size: 50
            },
            {
                header: "Max Volume [MW]",
                id: "Quantity",
                accessorFn: ({ Quantity }) => Quantity || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Ask",
                id: "MPrice",
                accessorFn: ({ MPrice }) => MPrice.toFixed(3) || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Updated at",
                id: "CreatedAtStr",
                accessorFn: ({ CreatedAtStr }) => CreatedAtStr || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            }
        ], []
    );

    const actionsMenuForSell = useMemo(
        () =>
            ({ row }) =>
                [
                    <Button
                        key={'sell'}
                        variant='outlined'
                        color='error'
                        sx={{
                            p: 0,
                            m: 0,
                            borderColor: '1px solid #EB6969',
                            color: '#EB6969',
                            fontSize: '13px',
                            '&:hover': {
                                borderColor: '1px solid #EB6969',
                                color: 'white',
                                backgroundColor: '#EB6969',
                            },
                        }}
                        onClick={() => handleOpenSellModal(row?.original)}
                    >
                        Sell
                    </Button>
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    // buy modal
    const [openBuyModal, setOpenBuyModal] = useState(false)
    const handleOpenBuyModal = (selectedContract) => {
        setOpenBuyModal(true)
        setSelectedContract(selectedContract)
    }
    const handleCloseBuyModal = () => setOpenBuyModal(false)

    const columnsForBuy = useMemo(
        () => [
            {
                header: "Contract",
                id: "Contract",
                accessorFn: ({ Contract }) => Contract || "",
                Cell: ({ renderedCellValue }) => <Typography sx={{ color: '#3B9D9D', fontSize: 'inherit', fontWeight: 'bold', pl: '8px' }}>{renderedCellValue}</Typography>,
                size: 50
            },
            {
                header: "Max Volume [MW]",
                id: "Quantity",
                accessorFn: ({ Quantity }) => Quantity || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Bid",
                id: "MPrice",
                accessorFn: ({ MPrice }) => MPrice.toFixed(3) || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Updated at",
                id: "CreatedAtStr",
                accessorFn: ({ CreatedAtStr }) => CreatedAtStr || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            }
        ], []
    );

    const actionsMenuForBuy = useMemo(
        () =>
            ({ row }) =>
                [
                    <Button
                        key={'buy'}
                        variant='outlined'
                        color='primary'
                        sx={{
                            p: 0,
                            m: 0,
                            borderColor: '1px solid #27A9FF',
                            color: '#27A9FF',
                            fontSize: '13px',
                            '&:hover': {
                                borderColor: '1px solid #27A9FF',
                                color: 'white',
                                backgroundColor: '#27A9FF',
                            },
                        }}
                        onClick={() => handleOpenBuyModal(row?.original)}
                    >
                        Buy
                    </Button>
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );


    return (
        <>
            {/* buy modal */}
            <Modal open={openBuyModal} onClose={handleCloseBuyModal}>
                <Buy
                    handleClose={handleCloseBuyModal}
                    open={openBuyModal}
                    selectedContract={selectedContract}
                    user={user}
                />
            </Modal>
            {/* sell modal */}
            <Modal open={openSellModal} onClose={handleCloseSellModal}>
                <Sell
                    handleClose={handleCloseSellModal}
                    open={openSellModal}
                    selectedContract={selectedContract}
                    user={user}
                />
            </Modal>
            <Box className='flex flex-col gap-4'>
                <ReactTable
                    data={dataForAsk}
                    columns={columnsForAsk}
                    actions={actionsMenuForBuy}
                    headerText={'Ask'}
                    renderToolbarInternalActions={
                        ({ table }) => (
                            <div onMouseDown={(e) => e.stopPropagation()}>
                                <MRT_ToggleGlobalFilterButton table={table} />
                            </div>
                        )
                    }
                />
                <ReactTable
                    data={dataForBuy}
                    columns={columnsForBuy}
                    actions={actionsMenuForSell}
                    headerText={'Bid'}
                    renderToolbarInternalActions={
                        ({ table }) => (
                            <div onMouseDown={(e) => e.stopPropagation()}>
                                <MRT_ToggleGlobalFilterButton table={table} />
                            </div>
                        )
                    }
                />
            </Box>
        </>
    )
}

export default Intraday
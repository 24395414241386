import { useEffect, useState } from 'react';
import { ProSidebar, Menu, MenuItem, SidebarHeader } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Box, Typography, Badge, Tooltip } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { gas_sidebar_options, electricity_sidebar_options } from '@data';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { COMMON_PATH, ELECTRICITY_PATH, GAS_PATH } from '@utils';
import { useGetChatUnreadMessageCountQuery } from '@store/feature/chat/chatApi';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

const Item = ({ title, to, icon, suffix, tooltipText }) => {
    const location = useLocation();

    const menuItem = (
        <MenuItem
            active={location.pathname.includes(to)}
            icon={icon}
            suffix={suffix}
        >
            <Typography variant="body2">{title}</Typography>
            <Link to={to} />
        </MenuItem>
    );

    return tooltipText ? (
        <Tooltip title={tooltipText} arrow placement="right">
            {menuItem}
        </Tooltip>
    ) : (
        menuItem
    );
};

const Sidebar = () => {
    const { user } = useSelector((state) => state.user);
    const { data: unreadMessage } = useGetChatUnreadMessageCountQuery();
    const location = useLocation();

    const [gasSidebar, setGasSidebar] = useState(gas_sidebar_options);

    useEffect(() => {
        const tempGasSidebar = cloneDeep(gas_sidebar_options);

        if (user?.HideNCG === true) {
            tempGasSidebar[0].childrens = tempGasSidebar[0].childrens.filter(
                (item) => item.label !== 'THE'
            );
        }
        if (user?.HideTTF === true) {
            tempGasSidebar[0].childrens = tempGasSidebar[0].childrens.filter(
                (item) => item.label !== 'TTF'
            );
        }
        if (user?.HidePEGNord === true) {
            tempGasSidebar[0].childrens = tempGasSidebar[0].childrens.filter(
                (item) => item.label !== 'PEG'
            );
        }
        if (user?.HideIntraday === true) {
            tempGasSidebar[0].childrens = tempGasSidebar[0].childrens.filter(
                (item) => item.label !== 'Intraday'
            );
        }
        if (user?.HideOrders === true) {
            tempGasSidebar[1].childrens = tempGasSidebar[1].childrens.filter(
                (item) => item.label !== 'Orders'
            );
        }
        setGasSidebar(tempGasSidebar);
    }, [user]);

    return (
        <ProSidebar>
            <SidebarHeader className="bg-[#27485D] h-[69px]">
                <Menu>
                    <MenuItem>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Box
                                sx={{
                                    borderRight: '3px solid #fff'
                                }}
                            >
                                <Link
                                    to={COMMON_PATH.HOME}
                                    style={{
                                        fontSize: '1.5rem',
                                        fontWeight: 'bold',
                                        color: 'white',
                                        textDecoration: 'none',
                                        '&:hover': {
                                            color: '#2FB5FE'
                                        },
                                        paddingRight: '10px'
                                    }}
                                >
                                    Price
                                    <span style={{ color: '#2EA3F0' }}>
                                        Blick
                                    </span>
                                </Link>
                            </Box>
                        </Box>
                    </MenuItem>
                </Menu>
            </SidebarHeader>
            <Menu>
                {location.pathname.includes('gas')
                    ? gasSidebar.map((options, index) => (
                        <Box key={index}>
                            <Typography
                                variant="h6"
                                sx={{ paddingLeft: '16px' }}
                            >
                                {options.header}
                            </Typography>
                            {options.childrens.map((item, childIndex) => {
                                if (item.isAdmin && !user?.Admin) {
                                    return null;
                                }
                                return (
                                    <Item
                                        key={`${index}-${childIndex}`}
                                        title={item.label}
                                        to={item.link}
                                        icon={item.icon}
                                    />
                                );
                            })}
                        </Box>
                    ))
                    : electricity_sidebar_options?.map((options, index) => (
                        <Box key={index}>
                            <Typography
                                variant="h6"
                                sx={{ paddingLeft: '16px' }}
                            >
                                {options.header}
                            </Typography>
                            {options.childrens?.map((item, childIndex) => (
                                <Item
                                    key={`${index}-${childIndex}`}
                                    title={item.label}
                                    to={item.link}
                                    icon={item.icon}
                                />
                            ))}
                        </Box>
                    ))}
                <MenuItem
                    icon={<ChatOutlinedIcon />}
                    suffix={
                        unreadMessage?.count &&
                        unreadMessage?.count >= 1 && (
                            <Badge
                                badgeContent={
                                    unreadMessage?.count > 9
                                        ? '9+'
                                        : unreadMessage?.count
                                }
                                color="primary"
                            />
                        )
                    }
                    style={{
                        backgroundColor: location.pathname.includes(GAS_PATH.CHAT) || location.pathname.includes(ELECTRICITY_PATH.CHAT) ? '#DFF2FF' : 'inherit',
                        color: location.pathname.includes(GAS_PATH.CHAT) || location.pathname.includes(ELECTRICITY_PATH.CHAT) ? '#3AACFE' : 'inherit',
                        width: location.pathname.includes(GAS_PATH.CHAT) || location.pathname.includes(ELECTRICITY_PATH.CHAT) ? '90%' : 'inherit',
                        borderRadius: location.pathname.includes(GAS_PATH.CHAT) || location.pathname.includes(ELECTRICITY_PATH.CHAT) ? '5px' : 'inherit',
                    }}
                >
                    <Typography>
                        Chat
                    </Typography>
                    <Link
                        to={
                            location.pathname.includes('gas')
                                ? GAS_PATH.CHAT
                                : ELECTRICITY_PATH.CHAT
                        }
                    />
                </MenuItem>
            </Menu>
        </ProSidebar>
    );
};

export default Sidebar;

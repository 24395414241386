import React, { forwardRef, useState } from 'react'
import { CustomModal } from '@components'
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    Tooltip,
    Typography
} from '@mui/material'
import DoneIcon from '@mui/icons-material/Done';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '@utils';
import { apiGetProfile, apiUpdateUser, apiGetUsersForAccount } from '@store';
import { available_contract_options, available_product_options } from '@data'
import { useParams } from 'react-router-dom';

const emailTooltip = `General email where trade confirmations will be sent along with the user who did that trade.`
const purchaseLimitTooltip = `Soft purchase limit per year in MW.`

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const EditUsers = forwardRef(({ selectedUser, handleClose }, ref) => {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.user);
    const { account_uuid } = useParams()

    const [state, setState] = useState({
        fullname: selectedUser?.fullname || '',
        email: selectedUser?.email || '',
        phone: selectedUser?.phone || '',
        visiblecontracts: selectedUser?.visiblecontracts?.map(contract => contract.name) || [],
        visibleproducts: selectedUser?.visibleproducts?.map(product => product.name) || [],
        hide_buy_button: selectedUser?.hide_buy_button || false,
        hide_sell_button: selectedUser?.hide_sell_button || false,
        hide_trailing_stop: selectedUser?.hide_trailing_stop || false,
        disabled: selectedUser?.disabled || false,
        orderlimit: selectedUser?.orderlimit || 0,
        hideflex: selectedUser?.hideflex || false,
        username: selectedUser?.username || '',
        temporary: selectedUser?.temporary || 0,
        valid_for: selectedUser?.valid_for || 0,
    })

    // for visible contracts
    const handleChangeMultipleContract = (event) => {
        const { target: { value } } = event;
        setState({
            // On autofill we get a stringified value.
            ...state,
            visiblecontracts: typeof value === 'string' ? value.split(',') : value
        });
    };

    // for visible products
    const handleChangeMultipleProduct = (event) => {
        const { target: { value } } = event;
        setState({
            // On autofill we get a stringified value.
            ...state,
            visibleproducts: typeof value === 'string' ? value.split(',') : value,
        });
    };

    // for checkboxes
    const handleChangeCheckbox = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    // handle toggle change
    const handleToggleChange = (e) => {
        const { name, checked } = e.target
        setState({
            ...state,
            [name]: checked
        })
    }

    // handle change
    const handleChange = (e) => {
        const { name, value } = e.target
        setState(prev => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const data = {
            ...state,
            visiblecontracts: state.visiblecontracts.map(contract => ({ id: available_contract_options.find(c => c.name === contract).id, name: contract })),
            visibleproducts: state.visibleproducts.map(product => ({ id: available_product_options.find(p => p.name === product).id, name: product })),
            orderlimit: parseInt(state.orderlimit),
        }

        const resp = await dispatch(apiUpdateUser(data))
        if (resp.meta.requestStatus === 'fulfilled') {
            handleClose()
            dispatch(apiGetUsersForAccount(account_uuid))
            if (user?.UUID === selectedUser?.uuid) {
                dispatch(apiGetProfile())
            }
            showToast('success', 'User updated successfully')
        } else if (resp.meta.requestStatus === 'rejected') {
            showToast('error', resp?.payload || 'An error occurred')
        }
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Edit User`}
            buttonText={'Submit'}
            ref={ref}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Typography sx={{ fontWeight: 'bold' }}>Enable/Disable:</Typography>
                        <Switch
                            name="disabled"
                            checked={state.disabled}
                            onChange={handleToggleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Box>
                    <Box>
                        <Typography>
                            {selectedUser?.username}
                        </Typography>
                        <Typography>
                            {selectedUser?.AccountName}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: '1rem',
                    }}
                >
                    <FormControl fullWidth={true} variant="outlined">
                        <InputLabel
                            id="nameLabel"
                            shrink
                        >
                            Name
                        </InputLabel>
                        <OutlinedInput
                            id="nameLabel"
                            label="Name"
                            name='fullname'
                            onChange={handleChange}
                            value={state.fullname}
                        />
                    </FormControl>
                    <Tooltip
                        title={emailTooltip}
                        arrow
                    >
                        <FormControl fullWidth={true} variant="outlined">
                            <InputLabel
                                id="emailLabel"
                                shrink
                            >
                                Email
                            </InputLabel>
                            <OutlinedInput
                                id="email"
                                label="emailLabel"
                                name='email'
                                onChange={handleChange}
                                value={state.email}
                            />
                        </FormControl>
                    </Tooltip>
                    <FormControl fullWidth={true} variant="outlined">
                        <InputLabel
                            id="phoneLabel"
                            sx={{ bgcolor: '#F5F5F5', px: '4px' }}
                            shrink
                        >
                            Phone Number
                        </InputLabel>
                        <OutlinedInput
                            id="phoneLabel"
                            label="Phone Number"
                            name='phone'
                            onChange={handleChange}
                            value={state.phone}
                        />
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-multiple-checkbox-label">Visible Contacts</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={state.visiblecontracts}
                            onChange={handleChangeMultipleContract}
                            input={<OutlinedInput label="Visible Contacts" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {available_contract_options?.map((contract, index) => (
                                <MenuItem key={index} value={contract.name}>
                                    <Checkbox checked={state.visiblecontracts.indexOf(contract.name) > -1} />
                                    <ListItemText primary={contract.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Tooltip
                        title={purchaseLimitTooltip}
                        arrow
                    >
                        <FormControl fullWidth={true} variant="outlined">
                            <InputLabel
                                id="purchaseLimitLabel"
                                sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                            >
                                Purchase Limit
                            </InputLabel>
                            <OutlinedInput
                                id="purchaseLimitLabel"
                                label="Purchase Limit"
                                name='orderlimit'
                                type='number'
                                inputProps={{ step: 10 }}
                                onChange={handleChange}
                                value={state.orderlimit}
                            />
                        </FormControl>
                    </Tooltip>
                    <FormControl>
                        <InputLabel id="demo-multiple-checkbox-label">Visible Products</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={state.visibleproducts}
                            onChange={handleChangeMultipleProduct}
                            input={<OutlinedInput label="Visible Products" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {available_product_options?.map((product, index) => (
                                <MenuItem key={index} value={product.name}>
                                    <Checkbox checked={state.visibleproducts.indexOf(product.name) > -1} />
                                    <ListItemText primary={product.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontWeight: 'bold'
                        }}
                    >
                        Please specify you want to disable
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.hide_buy_button}
                                    onChange={handleChangeCheckbox}
                                    name="hide_buy_button"
                                />
                            }
                            label="Disable Buy"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.hide_sell_button}
                                    onChange={handleChangeCheckbox}
                                    name="hide_sell_button"
                                />
                            }
                            label="Disable Sell"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.hide_trailing_stop}
                                    onChange={handleChangeCheckbox}
                                    name="hide_trailing_stop"
                                />
                            }
                            label="Disable TrailingStop"
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 'bold'
                            }}
                        >
                            Visible Products
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            {selectedUser?.visibleproducts?.map((product, index) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '2px'
                                    }}
                                    key={index}
                                >
                                    <DoneIcon fontSize='small' />
                                    <Typography>{product?.name} </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 'bold'
                            }}
                        >
                            Visible Contracts
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            {selectedUser?.visiblecontracts?.map((contract, index) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '2px'
                                    }}
                                    key={index}
                                >
                                    <DoneIcon fontSize='small' />
                                    <Typography>{contract?.name}</Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </CustomModal>
    )
})

export default EditUsers
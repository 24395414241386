import React from 'react'
import { CommonContainer } from '@components'
import { Box, Typography } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import InfoIcon from '@mui/icons-material/Info';

const Help = () => {
    return (
        <CommonContainer>
            <Typography sx={{ fontSize: '24px', mb: '6px' }}>Neep Help?</Typography>
            <Box display={'flex'} alignItems={'center'} gap={1} mb={2}>
                <EmailIcon fontSize='medium' />
                <Typography sx={{ fontSize: '16px', color: '#000' }}>info@setrading.ch</Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'} gap={1} mb={2}>
                <LocalPhoneIcon fontSize='medium' />
                <Typography sx={{ fontSize: '16px', color: '#000' }}>+41 44 250 42 90</Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'} gap={1}>
                <InfoIcon fontSize='medium' />
                <Typography sx={{ fontSize: '16px', color: '#000' }}>v2024 Priceblick</Typography>
            </Box>
        </CommonContainer>
    )
}

export default Help
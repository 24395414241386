import React, { useMemo } from 'react'
import { ReactTable } from '@components';
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import { Box, Typography } from '@mui/material';

const AnalysisSignal = ({ analysisSignal }) => {
    const analysisSignalColumn = useMemo(
        () => [
            {
                header: "Analysis Name",
                id: "name",
                Cell: ({ row }) => <>{row?.original?.name}</>,
                size: 50
            },
            {
                header: "Date",
                id: "date",
                Cell: ({ row }) => <>{new Date(row?.original?.date).toISOString().split('T')[0]}</>,
                size: 50
            },
            {
                header: "Signal",
                id: "signal",
                Cell: ({ row }) => <>{row?.original?.signal === 'Buy' ? <Typography sx={{ color: 'green' }}>Buy</Typography> : row?.original?.signal === 'Sell' ? <Typography sx={{ color: 'red' }}>Sell</Typography> : <Typography sx={{ color: 'black' }}>{row?.original?.signal}</Typography>}</>,
                size: 50
            },
            {
                header: "Note",
                id: "note",
                Cell: ({ row }) => <>{row?.original?.note}</>,
                size: 50
            }
        ], []
    );

    return (
        <Box mt={2}>
            <ReactTable
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                        </div>
                    )
                }
                headerText={`Analysis Signal`}
                columns={analysisSignalColumn}
                data={analysisSignal || []}
            />
        </Box>
    )
}

export default AnalysisSignal
import { GAS_PATH, ELECTRICITY_PATH } from '@utils';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
// import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
// import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import folderIcon from '../assets/svg/folder.svg';

// gas sidebar options
export let gas_trade_pages = {
    header: 'Trade',
    icon: <SwapVertOutlinedIcon />,
    childrens: [
        {
            label: 'THE',
            link: GAS_PATH.THE_EUR,
            icon: <img src={folderIcon} alt="folder" width={20} />
        },
        {
            label: 'TTF',
            link: GAS_PATH.TTF_EUR,
            icon: <img src={folderIcon} alt="folder" width={20} />
        },
        {
            label: 'PEG',
            link: GAS_PATH.PEG_EUR,
            icon: <img src={folderIcon} alt="folder" width={20} />
        },
        {
            label: 'Market Place',
            link: GAS_PATH.MARKET_PLACE,
            icon: <img src={folderIcon} alt="folder" width={20} />
        },
        {
            label: 'Intraday',
            link: GAS_PATH.INTRADAY,
            icon: <WatchLaterOutlinedIcon />
        }
    ]
};

export let gas_discover_pages = {
    header: 'Discover',
    icon: <ExploreOutlinedIcon />,
    childrens: [
        {
            label: 'Order Logs',
            link: GAS_PATH.ORDERS,
            icon: <LayersOutlinedIcon />
        },
        {
            label: 'Watchlist',
            link: GAS_PATH.WATCHLIST,
            icon: <RemoveRedEyeOutlinedIcon />
        },
        // {
        //     label: 'Overview',
        //     link: GAS_PATH.OVERVIEW,
        //     icon: <DonutSmallOutlinedIcon />
        // },
        // {
        //     label: 'Market',
        //     link: GAS_PATH.MARKET,
        //     icon: <StoreMallDirectoryOutlinedIcon />
        // },
        {
            label: 'News Feed',
            link: GAS_PATH.NEWS_FEED,
            icon: <FeedOutlinedIcon />
        },
        {
            label: 'Price Profiling',
            link: GAS_PATH.PRICE_PROFILING,
            icon: <LocalOfferOutlinedIcon />
        },
        {
            label: 'Notifications',
            link: GAS_PATH.NOTIFICATIONS,
            icon: <NotificationsOutlinedIcon />
        }
    ]
};

export let gas_manage_pages = {
    header: 'Manage',
    icon: <DisplaySettingsOutlinedIcon />,
    childrens: [
        {
            label: 'Calendar',
            link: GAS_PATH.CALENDAR,
            icon: <CalendarMonthOutlinedIcon />
        },
        {
            label: 'Help',
            link: GAS_PATH.HELP,
            icon: <HelpOutlineOutlinedIcon />
        },
        {
            label: 'Settings',
            link: GAS_PATH.SETTINGS,
            icon: <SettingsOutlinedIcon />,
            isAdmin: true
        }
    ]
};

export let gas_sidebar_options = [
    gas_trade_pages,
    gas_discover_pages,
    gas_manage_pages
];

// electricity sidebar options
export let electricity_trade_pages = {
    header: 'Trade',
    icon: <SwapVertOutlinedIcon />,
    childrens: [
        {
            label: 'CHE-EUR',
            link: ELECTRICITY_PATH.CHE_EUR,
            icon: <img src={folderIcon} alt="folder" width={20} />
        },
        {
            label: 'CH-CHF',
            link: ELECTRICITY_PATH.CH_CHF,
            icon: <img src={folderIcon} alt="folder" width={20} />
        }
    ]
};

export let electricity_discover_pages = {
    header: 'Discover',
    icon: <ExploreOutlinedIcon />,
    childrens: [
        {
            label: 'Calendar',
            link: ELECTRICITY_PATH.CALENDAR,
            icon: <CalendarMonthOutlinedIcon />
        },
        {
            label: 'News Feed',
            link: ELECTRICITY_PATH.NEWS_FEED,
            icon: <FeedOutlinedIcon />
        },
        {
            label: 'Orders',
            link: ELECTRICITY_PATH.ORDERS,
            icon: <LayersOutlinedIcon />
        },
        {
            label: 'Disclaimer',
            link: ELECTRICITY_PATH.DISCLAIMER,
            icon: <WarningAmberOutlinedIcon />
        },
        {
            label: 'Help',
            link: ELECTRICITY_PATH.HELP,
            icon: <HelpOutlineOutlinedIcon />
        }
    ]
};

export let electricity_sidebar_options = [
    electricity_trade_pages,
    electricity_discover_pages
];

import { Box, IconButton, InputLabel, TextField } from '@mui/material'
import React from 'react'
import { useCreateChatMessageMutation, useGetChatProfileQuery, useGetChatUserQuery } from '@store/feature/chat/chatApi';
import { useParams } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';

const SendChat = () => {
    const { id } = useParams();
    const [createChatMessage] = useCreateChatMessageMutation();
    const { data: profile } = useGetChatProfileQuery();

    const { data: userProfile } = useGetChatUserQuery(id);

    const removeImage = (index) => { setMyMessage((prev) => ({ ...prev, files: Array.from(prev.files).filter((_, i) => i !== index) })) }

    const [myMessage, setMyMessage] = React.useState({
        body: '',
        from: profile?.uuid,
        to: id,
        files: []
    });

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            handleSendMessage(event)
        }
    };

    const handleChange = (event) => {
        if (event.key === 'Enter' && event.shiftKey) {
            setMyMessage(prev => ({ ...prev, body: event.target.value + '\n' }))
        } else {
            setMyMessage(prev => ({ ...prev, body: event.target.value }))
        }
    }

    const handleFileChange = (e) => {
        setMyMessage({
            ...myMessage,
            files: e.target.files
        });
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        try {
            if (!myMessage.body && myMessage.files.length === 0) {
                return;
            }

            const message = {
                ...myMessage,
                body: myMessage.body.trim(),
                to: id,
                files: myMessage.files
            };

            setMyMessage({
                ...myMessage,
                body: '',
                to: id,
                files: []
            });

            const formData = new FormData();
            formData.append('body', message.body);
            formData.append('to', message.to);

            if (message.files) {
                for (let i = 0; i < message.files.length; i++) {
                    formData.append('files', message.files[i]);
                }
            }

            const response = await createChatMessage(formData);

            if (response.error) {
                console.error('Failed to send message:', response.error);
                return;
            }
              // Scroll to the bottom element on successful message submission
            const bottomElement = document.getElementById('bottom');
        if (bottomElement) {
            bottomElement.scrollIntoView({ behavior: 'smooth' });
        }
        } catch (error) {
            console.log(error, 'Error in handleSendMessage');
        }

        
    };
    return (
        <Box
            sx={{
                maxHeight: '500px',
                minHeight: '64px',
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
                m: '8px',
            }}
            component="form"
            onSubmit={handleSendMessage}
        >
            {/* text field */}
            <TextField
                fullWidth
                size="small"
                name="body"
                variant='standard'
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                value={myMessage.body}
                placeholder={`Message ${userProfile?.user?.fullname}`}
                InputProps={{
                    disableUnderline: true, // <== added this
                }}
                multiline
                sx={{
                    px: '8px',
                    pt: '8px',
                    width: '100%',
                    maxHeight: '100px',
                    minHeight: '64px',
                    overflowY: 'auto',
                }}
            />
            {/* end of text field */}
            {/* selected image */}
            <Box
                sx={{
                    display: 'flex',
                    gap: '8px',
                    flexWrap: 'wrap',
                    maxHeight: '200px',
                    px: '8px',
                    mt: '8px',
                }}
            >
                {Array.from(myMessage.files).map((file, index) => (
                    <Box
                        key={index}
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                    >
                        <img
                            src={URL.createObjectURL(file)}
                            alt="preview"
                            style={{
                                width: '50px',
                                height: '50px',
                                borderRadius: '8px',
                                objectFit: 'cover'
                            }}
                        />
                        <Box
                            onClick={() => removeImage(index)}
                            sx={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                transform: ' translate(50%, -50%)',
                                backgroundColor: 'rgba(0,0,0,0.8)',
                                color: 'white',
                                borderRadius: '50%',
                                padding: '4px',
                                width: '15px',
                                height: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer'
                            }}
                            title='Remove File'
                        >
                            <CloseIcon
                                sx={{
                                    fontSize: '0.8rem'
                                }}
                            />
                        </Box>
                    </Box>
                ))}
            </Box>
            {/* end of selected image */}
            <Box
                sx={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                {/* select image */}
                <Box
                    sx={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center',
                    }}
                >
                    <input
                        color="primary"
                        type='file'
                        name="files"
                        onChange={handleFileChange}
                        style={{
                            display: 'none'
                        }}
                        id="file"
                        multiple
                    />
                    <InputLabel htmlFor="file">
                        <IconButton
                            color="primary"
                            component="span"
                            sx={{
                                transform: 'rotate(45deg)',
                            }}
                        >
                            <AttachFileIcon fontSize='small' />
                        </IconButton>
                    </InputLabel>
                </Box>
                {/* end of select image */}

                {/* send button */}
                <IconButton
                    color="primary"
                    type="submit"
                >
                    <SendIcon fontSize='small' />
                </IconButton>
                {/* end of send button */}
            </Box>
        </Box>
    )
}

export default SendChat
import React from 'react'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import { CommonContainer } from '@components'
import { Typography } from '@mui/material';

const ForecastingChart = ({ ncgDataPoints, currency }) => {
    const data = ncgDataPoints?.forecast_data?.map((item) => {
        return {
            date: item.date_str,
            ...item.ask_forecast
        }
    }) || []

    const newData = ncgDataPoints?.asks?.slice(-3)?.map((item) => {
        return {
            date: item.date,
            timesfm_q_1: item.price,
            timesfm_q_2: item.price,
            timesfm_q_3: item.price,
            timesfm_q_4: item.price,
            timesfm_q_5: item.price,
            timesfm_q_6: item.price,
            timesfm_q_7: item.price,
            timesfm_q_8: item.price,
            timesfm_q_9: item.price,
        }
    }) || []

    const finalData = [...data, ...newData?.reverse()]

    return (
        <CommonContainer sx={{ mb: 2 }}>
            <Typography
                sx={{
                    fontSize: '20px',
                    color: '#27485D',
                    fontWeight: 500
                }}
            >
                {ncgDataPoints?.symbol} Forecasting Chart
            </Typography>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={finalData?.reverse() || []}
                    margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis
                        domain={['auto', 'auto']}
                        label={{
                            value: `${currency.toUpperCase()}/MWh`,
                            angle: -90,
                            position: 'insideLeft',
                        }}
                    />
                    <Tooltip
                        formatter={(value, name) => [`${value?.toFixed(3)} ${currency?.toUpperCase()}`, name]}
                        labelFormatter={(label) => `Date: ${label}`}
                    />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="timesfm_q_1"
                        stroke="blue" // blue
                        name='10th percentile'
                        strokeWidth={1}
                        dot={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="timesfm_q_2"
                        stroke="orange" // orange
                        name='20th percentile'
                        strokeWidth={1}
                        dot={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="timesfm_q_3"
                        stroke="green" // green
                        name='30th percentile'
                        strokeWidth={1}
                        dot={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="timesfm_q_4"
                        stroke="red" // red
                        name='40th percentile'
                        strokeWidth={1}
                        dot={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="timesfm_q_5"
                        stroke="purple" // purple
                        name='50th percentile'
                        strokeWidth={3}
                        dot={false}
                        activeDot={{ r: 8 }}
                    />
                    <Line
                        type="monotone"
                        dataKey="timesfm_q_6"
                        stroke="brown" // brown
                        name='60th percentile'
                        strokeWidth={1}
                        dot={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="timesfm_q_7"
                        stroke="pink" // pink
                        name='70th percentile'
                        strokeWidth={1}
                        dot={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="timesfm_q_8"
                        stroke="grey" // grey
                        name='80th percentile'
                        strokeWidth={1}
                        dot={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="timesfm_q_9"
                        stroke="olive" // olive
                        name='90th percentile'
                        strokeWidth={1}
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </CommonContainer>
    )
}

export default ForecastingChart
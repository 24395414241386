import { Box, Button, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useSelector } from 'react-redux';
import { ReactTable, Filter, InfoModal } from '@components';
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import Buy from './Buy';
import Sell from './Sell';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { AppName, ELECTRICITY_EUR_TITLE } from '@utils';

const Eur = () => {
    useEffect(() => {
        document.title = `${AppName} | ${ELECTRICITY_EUR_TITLE}`
    }, [])

    const { cheEur, isGetCheEurLoading } = useSelector((state) => state.che);
    const { user } = useSelector((state) => state.user);
    const { disableTrading } = useSelector(state => state.the);

    const [selectedRow, setSelectedRow] = useState(null);

    // info modal
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const handleOpenInfoModal = () => { setOpenInfoModal(true); }
    const handleCloseInfoModal = () => { setOpenInfoModal(false); }

    // buy modal
    const [openBuyModal, setOpenBuyModal] = useState(false);
    const handleOpenBuyModal = useCallback((selectedRow) => {
        setSelectedRow(selectedRow);
        setOpenBuyModal(true);
    }, [])
    const handleCloseBuyModal = () => { setOpenBuyModal(false); }

    // sell modal
    const [openSellModal, setOpenSellModal] = useState(false);
    const handleOpenSellModal = useCallback((selectedRow) => {
        setSelectedRow(selectedRow);
        setOpenSellModal(true);
    }, [])
    const handleCloseSellModal = () => { setOpenSellModal(false); }

    // filter modal
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const handleOpenFilterModal = () => setOpenFilterModal(true)
    const handleCloseFilterModal = () => setOpenFilterModal(false)

    const data = useMemo(() => {
        let tempData = []
        if (cheEur) {
            tempData = cheEur?.symbols?.map(symbol => {
                return {
                    name: symbol,
                    ...cheEur[symbol]
                }
            }) || []
        }
        return tempData
    }, [cheEur])

    const columns = useMemo(
        () => [
            {
                header: "Contract",
                id: "name",
                accessorFn: ({ name }) => name || "",
                Cell: ({ renderedCellValue }) =>
                    <Typography
                        sx={{
                            color: '#3B9D9D',
                            fontSize: 'inherit',
                            fontWeight: 'bold',
                            pl: '8px'
                        }}
                    >
                        {renderedCellValue}
                    </Typography>,
                size: 50
            },
            {
                header: "BID",
                id: "bid",
                accessorFn: ({ bid }) => bid || '',
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "ASK",
                id: "ask",
                accessorFn: ({ ask }) => ask || '',
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
        ], []
    );

    // start of buy sell restriction modal
    // convert user.StartTime and user.EndTime string to hh:mm
    const startTime = user?.StartTime?.split(':').slice(0, 2).join(':')
    const endTime = user?.EndTime?.split(':').slice(0, 2).join(':')
    const lunchStart = user?.LunchStart?.split(':').slice(0, 2).join(':')
    const lunchEnd = user?.LunchEnd?.split(':').slice(0, 2).join(':')

    // convert into berlin time
    // if current time is between startTime and endTime and not between lunchStart and lunchEnd then show modal
    var europeTime = new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" });
    var time = new Date(europeTime);
    var hours = time.getHours();
    var minutes = time.getMinutes();
    // Format current time like startTime and endTime (hh:mm)
    var currentTime = `${hours.toString().padStart(2, '0')}.${minutes.toString().padStart(2, '0')}`;
    // end of buy sell restriction modal

    const actionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <Box className='flex gap-4 items-center justify-center' key={'buttons'}>
                        {disableTrading?.disable === false && user?.HideBuyButton === false &&
                            <Button
                                variant='outlined'
                                color='primary'
                                sx={{
                                    p: 0,
                                    m: 0,
                                    borderColor: '1px solid #27A9FF',
                                    color: '#27A9FF',
                                    fontSize: '13px',
                                    '&:hover': {
                                        borderColor: '1px solid #27A9FF',
                                        color: 'white',
                                        backgroundColor: '#27A9FF',
                                    },
                                }}
                                onClick={() => {
                                    if (startTime <= currentTime && currentTime <= endTime && (currentTime < lunchStart || currentTime > lunchEnd)) {
                                        handleOpenBuyModal(row.original)
                                    } else {
                                        handleOpenInfoModal()
                                    }
                                }}
                                disabled={row?.original?.ask === 0}
                            >
                                Buy
                            </Button>
                        }
                        {disableTrading?.disable === false && user?.HideSellButton === false &&
                            <Button
                                variant='outlined'
                                color='error'
                                sx={{
                                    p: 0,
                                    m: 0,
                                    borderColor: '1px solid #EB6969',
                                    color: '#EB6969',
                                    fontSize: '13px',
                                    '&:hover': {
                                        borderColor: '1px solid #EB6969',
                                        color: 'white',
                                        backgroundColor: '#EB6969',
                                    },
                                }}
                                onClick={() => {
                                    if (startTime <= currentTime && currentTime <= endTime && (currentTime < lunchStart || currentTime > lunchEnd)) {
                                        handleOpenSellModal(row.original)
                                    } else {
                                        handleOpenInfoModal()
                                    }
                                }}
                                disabled={row?.original?.bid === 0}
                            >
                                Sell
                            </Button>
                        }
                    </Box>
                ],
        [user, disableTrading, handleOpenBuyModal, handleOpenSellModal]
    );

    const [filteredData, setFilteredData] = useState([])

    // filter state
    const [filter, setFilter] = useState({
        typeOfContract: '',
        bidPrice: {
            min: '',
            max: ''
        },
        askPrice: {
            min: '',
            max: ''
        }
    })

    useEffect(() => {
        if (data?.length > 0) {
            if (filter.typeOfContract && filter.typeOfContract === "yearly") {
                setFilteredData(data?.filter(item => item?.name?.includes('CAL')) || [])
            } else if (filter.typeOfContract && filter.typeOfContract === "monthly") {
                const months = ['Jan ', 'Feb ', 'Mar ', 'Apr ', 'May ', 'Jun ', 'Jul ', 'Aug ', 'Sep ', 'Oct ', 'Nov ', 'Dec ']
                setFilteredData(data?.filter(item => months?.includes(item?.name?.slice(0, 3))) || [])
            } else if (filter.typeOfContract && filter.typeOfContract === "quarterly") {
                setFilteredData(data?.filter(item => item?.name?.includes('Q')) || [])
            } else if (filter.typeOfContract && filter.typeOfContract === "winter") {
                setFilteredData(data?.filter(item => item?.name?.includes('WINTER')) || [])
            } else if (filter.typeOfContract && filter.typeOfContract === "summer") {
                setFilteredData(data?.filter(item => item?.name?.includes('SUMMER')) || [])
            } else {
                setFilteredData(data)
            }
        }
    }, [filter, data])

    useEffect(() => {
        if (data?.length > 0) {
            if (filter.bidPrice.min && filter.bidPrice.max) {
                setFilteredData(data?.filter(item => item.bid >= filter.bidPrice.min && item.bid <= filter.bidPrice.max) || [])
            } else if (filter.bidPrice.min) {
                setFilteredData(data?.filter(item => item.bid >= filter.bidPrice.min) || [])
            } else if (filter.bidPrice.max) {
                setFilteredData(data?.filter(item => item.bid <= filter.bidPrice.max) || [])
            } else {
                setFilteredData(data)
            }
        }
    }, [filter.bidPrice, data])

    useEffect(() => {
        if (data?.length > 0) {
            if (filter.askPrice.min && filter.askPrice.max) {
                setFilteredData(data?.filter(item => item.ask >= filter.askPrice.min && item.ask <= filter.askPrice.max) || [])
            } else if (filter.askPrice.min) {
                setFilteredData(data?.filter(item => item.ask >= filter.askPrice.min) || [])
            } else if (filter.askPrice.max) {
                setFilteredData(data?.filter(item => item.ask <= filter.askPrice.max) || [])
            } else {
                setFilteredData(data)
            }
        }
    }, [filter.askPrice, data])

    return (
        <>
            {/* time modal */}
            <Modal open={openInfoModal} onClose={handleCloseInfoModal}>
                <InfoModal
                    handleClose={handleCloseInfoModal}
                    startTime={startTime}
                    endTime={endTime}
                    lunchStart={lunchStart}
                    lunchEnd={lunchEnd}
                />
            </Modal>
            {/* buy modal */}
            <Modal open={openBuyModal} onClose={handleCloseBuyModal}>
                <Buy
                    handleClose={handleCloseBuyModal}
                    open={openBuyModal}
                    // eur={eur}
                    selectedContract={selectedRow}
                    user={user}
                />
            </Modal>
            {/* sell modal */}
            <Modal open={openSellModal} onClose={handleCloseSellModal}>
                <Sell
                    handleClose={handleCloseSellModal}
                    open={openSellModal}
                    // eur={eur}
                    selectedContract={selectedRow}
                    user={user}
                />
            </Modal>
            {/* filter modal */}
            <Modal open={openFilterModal} onClose={handleCloseFilterModal}>
                <Filter
                    handleClose={handleCloseFilterModal}
                    open={openFilterModal}
                    filter={filter}
                    setFilter={setFilter}
                />
            </Modal>
            <ReactTable
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <Tooltip title='Filter'>
                                <IconButton
                                    onClick={handleOpenFilterModal}
                                >
                                    <FilterAltOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                }
                headerText={'CH Power (EUR)'}
                columns={columns}
                data={filteredData || []}
                actions={actionsMenu}
            // isLoading={isGetCheEurLoading}
            />
        </>
    )
}

export default Eur
import React, { useEffect, useMemo, useState } from 'react'
import { forwardRef } from 'react'
import { CustomModal, ReactTable } from '@components'
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Switch, Tooltip, Typography } from '@mui/material'
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers'
import { useDispatch } from 'react-redux';
import { apiGetAccount, apiUpdateAccount } from '@store'
import { showToast } from '@utils'

const emailTooltip = `General email where trade confirmations will be sent along with the user who did that trade.`
const marginTooltip = `Margin to add on all products. Negative number will be deduced. Margin will apply to all products.`
const emailFooterTooltip = `Footer that appears on your trade confirmation email.`

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Edit = forwardRef(({ handleClose, account, endpoint }, ref) => {
    const dispatch = useDispatch()

    const [state, setState] = useState({
        company_name: account?.company_name || '',
        email: account?.email || '',
        margin: account?.margin || '',
        country: account?.country || '',
        address: account?.address || '',
        email_footer: account?.email_footer || '',
        endpoints: account?.endpoints?.map((endpoint) => endpoint.uuid) || [],
        starttime: dayjs(account?.starttime, 'HH.mm') || '',
        endtime: dayjs(account?.endtime, 'HH.mm') || '',
        lunchstart: dayjs(account?.lunchstart, 'HH.mm') || '',
        lunchend: dayjs(account?.lunchend, 'HH.mm') || '',
        disabled: account?.disabled || false,
        can_sell_to: account?.can_sell_to || [],
        can_buy_from: account?.can_buy_from || [],
        uuid: account?.uuid || '',
        short_name: account?.short_name || '',
    })

    useEffect(() => {
        setState({
            company_name: account?.company_name || '',
            email: account?.email || '',
            margin: account?.margin || '',
            country: account?.country || '',
            address: account?.address || '',
            email_footer: account?.email_footer || '',
            endpoints: account?.endpoints?.map((endpoint) => endpoint.uuid) || [],
            starttime: dayjs(account?.starttime, 'HH.mm') || '',
            endtime: dayjs(account?.endtime, 'HH.mm') || '',
            lunchstart: dayjs(account?.lunchstart, 'HH.mm') || '',
            lunchend: dayjs(account?.lunchend, 'HH.mm') || '',
            disabled: account?.disabled || false,
            can_sell_to: account?.can_sell_to || [],
            can_buy_from: account?.can_buy_from || [],
            uuid: account?.uuid || '',
            short_name: account?.short_name || '',
        })
    }, [account])

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const handleToggleChange = (e) => {
        const { name, checked } = e.target
        setState({
            ...state,
            [name]: checked
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const selectedEndpoints = endpoint.filter(({ uuid }) => state?.endpoints.includes(uuid))

        const data = {
            ...state,
            starttime: dayjs(state?.starttime).format('HH.mm'),
            endtime: dayjs(state?.endtime).format('HH.mm'),
            lunchstart: dayjs(state?.lunchstart).format('HH.mm'),
            lunchend: dayjs(state?.lunchend).format('HH.mm'),
            endpoints: selectedEndpoints,
            margin: parseFloat(state?.margin),
        }

        const resp = await dispatch(apiUpdateAccount(data))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            handleClose()
            dispatch(apiGetAccount())
            showToast('success', 'Account updated successfully')
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp?.payload || 'An error occurred')
        }
    }

    // selected Endpoint table data
    const column = useMemo(
        () => [
            {
                header: "Endpoints",
                id: "name",
                accessorFn: ({ name }) => name || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Location",
                id: "location",
                accessorFn: ({ location }) => location || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            }
        ], []
    )

    const handleChangeMultiple = (event) => {
        const { target: { value }, } = event;
        setState({
            ...state,
            endpoints: typeof value === 'string' ? value.split(',') : value,
        });
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={account?.company_name}
            buttonText={'Submit'}
            ref={ref}
        >
            <Box className='flex flex-col gap-3 mb-3 justify-center'>
                <Box className='flex gap-6 items-center'>
                    <Box className='flex gap-2'>
                        <Typography sx={{ fontWeight: 'bold' }}>Can sell to:</Typography>
                        <Typography color={'black'}>{account?.can_sell_to[0]}</Typography>
                    </Box>
                    <Box className='flex gap-2'>
                        <Typography sx={{ fontWeight: 'bold' }}>Can buy from:</Typography>
                        <Typography color={'black'}>{account?.can_sell_to[0]}</Typography>
                    </Box>
                    <Box className='flex gap-2 items-center'>
                        <Typography sx={{ fontWeight: 'bold' }}>Enable/Disable:</Typography>
                        <Switch
                            name="disabled"
                            checked={state?.disabled}
                            onChange={handleToggleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Box>
                </Box>
                <Box className='flex gap-3 mt-3'>
                    <Box className='flex flex-col gap-3'>
                        <Tooltip
                            title={emailTooltip}
                            arrow
                        >
                            <FormControl fullWidth={true} variant="outlined">
                                <InputLabel
                                    id="Email"
                                    sx={{ bgcolor: '#F5F5F5' }}
                                >
                                    Email
                                </InputLabel>
                                <OutlinedInput
                                    id="companyName"
                                    label="Email"
                                    name='email'
                                    onChange={handleChange}
                                    value={state?.email}
                                />
                            </FormControl>
                        </Tooltip>
                        <FormControl fullWidth={true} variant="outlined">
                            <InputLabel
                                id="Address"
                                sx={{ bgcolor: '#F5F5F5' }}
                            >
                                Address
                            </InputLabel>
                            <OutlinedInput
                                id="companyName"
                                label="Address"
                                name='address'
                                onChange={handleChange}
                                value={state?.address}
                            />
                        </FormControl>
                        <Tooltip
                            title={marginTooltip}
                            arrow
                        >
                            <FormControl fullWidth={true} variant="outlined">
                                <InputLabel
                                    id="marginLabel"
                                    sx={{ bgcolor: '#F5F5F5' }}
                                >
                                    Margin
                                </InputLabel>
                                <OutlinedInput
                                    id="margin"
                                    label="marginLabel"
                                    name='margin'
                                    type='number'
                                    onChange={handleChange}
                                    value={state?.margin}
                                    inputProps={{ step: 0.01 }}
                                />
                            </FormControl>
                        </Tooltip>
                        {/* time picker */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label="Trading Start"
                                value={state?.starttime}
                                onChange={(newValue) => setState({ ...state, starttime: newValue })}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label="Lunch Start"
                                value={state?.lunchstart}
                                onChange={(newValue) => setState({ ...state, lunchstart: newValue })}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box className='flex flex-col gap-3'>
                        <FormControl fullWidth={true} variant="outlined">
                            <InputLabel
                                id="countryLabel"
                                sx={{ bgcolor: '#F5F5F5' }}
                            >
                                Country
                            </InputLabel>
                            <OutlinedInput
                                id="country"
                                label="countryLabel"
                                name='country'
                                onChange={handleChange}
                                value={state?.country}
                            />
                        </FormControl>
                        <Tooltip
                            title={emailFooterTooltip}
                            arrow
                        >
                            <FormControl fullWidth={true} variant="outlined">
                                <InputLabel
                                    id="emailFooterLabel"
                                    sx={{ bgcolor: '#F5F5F5' }}
                                >
                                    Email Footer
                                </InputLabel>
                                <OutlinedInput
                                    id="email_footer"
                                    label="emailFooterLabel"
                                    name='email_footer'
                                    onChange={handleChange}
                                    value={state?.email_footer}
                                />
                            </FormControl>
                        </Tooltip>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="demo-multiple-name-label">Endpoints</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                multiple
                                value={state?.endpoints}
                                onChange={handleChangeMultiple}
                                input={<OutlinedInput label="Endpoints" />}
                                MenuProps={MenuProps}
                            >
                                {endpoint.map((endpt) => (
                                    <MenuItem key={endpt.uuid} value={endpt.uuid}>
                                        {endpt.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label="Trading End"
                                value={state?.endtime}
                                onChange={(newValue) => setState({ ...state, endtime: newValue })}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label="Lunch End"
                                value={state?.lunchend}
                                onChange={(newValue) => setState({ ...state, lunchend: newValue })}
                            />
                        </LocalizationProvider>
                    </Box>
                </Box>
            </Box>
            <ReactTable
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                        </div>
                    )
                }
                headerText={'Selected Endpoints'}
                columns={column}
                data={account?.endpoints || []}
            />
        </CustomModal>
    )
})

export default Edit
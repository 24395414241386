import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link, Outlet } from 'react-router-dom';
import { GAS_PATH } from '@utils';
import { useSelector } from "react-redux";
import { Button, Modal } from "@mui/material";
import { AppName, GAS_PEG_TITLE } from "@utils"
import PriceNotification from './eur/PriceNotification';

function samePageLinkNavigation(event) {
    if (
        event.defaultPrevented ||
        event.button !== 0 || // ignore everything but left-click
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
}
function LinkTab(props) {
    return (
        <Tab
            component={Link}
            aria-current={props.selected && 'page'}
            {...props}
        />
    );
}

LinkTab.propTypes = {
    selected: PropTypes.bool,
};

export default function PEG() {
    const [value, setValue] = useState(0);

    useEffect(() => {
        document.title = `${AppName} | ${GAS_PEG_TITLE}`
    }, [])

    const { pegnordEur } = useSelector((state) => state.pegnord)

    const handleChange = (event, newValue) => {
        // event.type can be equal to focus with selectionFollowsFocus.
        if (
            event.type !== 'click' ||
            (event.type === 'click' && samePageLinkNavigation(event))
        ) {
            setValue(newValue);
        }
    };

    // price notification modal
    const [priceNotificationModal, setPriceNotificationModal] = useState(false)
    const handleOpenModal = useCallback(() => { setPriceNotificationModal(true); }, [])
    const handleCloseModal = useCallback(() => { setPriceNotificationModal(false); }, [])

    return (
        <Box sx={{ width: '100%' }}>
            {/* price notifiction modal */}
            <Modal open={priceNotificationModal} onClose={handleCloseModal}>
                <PriceNotification
                    handleClose={handleCloseModal}
                    open={priceNotificationModal}
                    eur={pegnordEur}
                />
            </Modal>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="nav tabs example"
                    role="navigation"
                    sx={{
                        background: '#fff',
                        color: 'white',
                        borderRadius: '5px',
                        padding: '5px',
                        margin: '0 0 10px 10px',
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                        minHeight: '45px',
                        '& .MuiTabs-indicator': {
                            display: 'none',
                        },
                        '& .MuiTab-root': {
                            minHeight: '35px',
                            height: '35px',
                            '&.Mui-selected': {
                                color: '#3AACFE',
                                background: '#DFF2FF',
                                borderRadius: '5px',
                                borderBottom: 'none',
                            },
                        },
                    }}
                >
                    <LinkTab label="EUR" to={GAS_PATH.PEG_EUR} />
                </Tabs>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        padding: '0 10px'
                    }}
                >
                    <Button
                        variant="contained"
                        className='page-button'
                        sx={{
                            bgcolor: '#0b9cfd',
                            '&:hover': {
                                bgcolor: '#0b9cfd',
                            },
                        }}
                        onClick={handleOpenModal}
                    >
                        Set Price Notification
                    </Button>
                </Box>
            </Box>
            <Box sx={{ padding: '10px' }}>
                <Outlet />
            </Box>
        </Box>
    );
}
import React, { useState, useEffect } from 'react'
import { CommonContainer } from '@components'
import { Grid, TextField, Typography, Button, Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { apiAddHedgeMargin } from '@store'
import { showToast } from '@utils'

const implementedConditions = [
    "When volume on any contract is less than",
    "When Months are more than",
    "When Quarter is more than",
    "When seasons are more than",
    "When Calendars are more than",
]

const HedgingMargin = () => {
    const dispatch = useDispatch()
    const { hedgeMargin, isGetHedgeMarginLoading } = useSelector((state) => state.hedgeMargin)

    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (hedgeMargin.length > 0) {
            const newState = hedgeMargin?.filter((item) => implementedConditions.includes(item.condition))?.map((item, index) => {
                return {
                    Condition: item.condition,
                    MW: item.mw,
                    Margin: item.margin
                }
            })
            setState(newState)
        }
    }, [hedgeMargin])

    const handleChange = (e, index) => {
        const { name, value } = e.target
        const list = [...state]
        list[index][name] = value
        setState(list)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = state.map((item) => {
            return {
                Condition: item.Condition,
                MW: parseInt(item.MW) || 0,
                Margin: parseFloat(item.Margin) || 0
            }
        })

        const resp = await dispatch(apiAddHedgeMargin(data))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            showToast('success', 'Margin added successfully')
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp.error.message)
        }
    }

    return (
        <CommonContainer>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Typography variant="h6">Conditions Margin</Typography>
                </Grid>
            </Grid>
            {isGetHedgeMarginLoading ?
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    Loading...
                </Box> :
                <>
                    {state.map((item, index) => (
                        <Grid container spacing={2} sx={{ alignItems: 'center' }} mb={1} key={index}>
                            <Grid item xs={6}>
                                <Typography
                                    color={'black'}
                                    value={item.Condition}
                                >
                                    {item.Condition}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    type='number'
                                    label="MW"
                                    variant="outlined"
                                    onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                                    name='MW'
                                    value={item.MW}
                                    onChange={(e) => handleChange(e, index)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    type='number'
                                    label="Margin"
                                    variant="outlined"
                                    onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                                    name='Margin'
                                    value={item.Margin}
                                    onChange={(e) => handleChange(e, index)}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </>}
            < Box sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: 4 }}>
                <Button
                    variant="contained"
                    className='page-button'
                    sx={{
                        bgcolor: '#0b9cfd',
                        '&:hover': {
                            bgcolor: '#0b9cfd',
                        },
                    }}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </Box>
        </CommonContainer >
    )
}

const initialState = [
    {
        Condition: "When volume on any contract is less than",
        MW: "",
        Margin: ""
    },
    // {
    //     Condition: "When Spot is more than",
    //     MW: "",
    //     Margin: ""
    // },
    // {
    //     Condition: "For every spot interval",
    //     MW: "",
    //     Margin: ""
    // },
    {
        Condition: "When Months are more than",
        MW: "",
        Margin: ""
    },
    // {
    //     Condition: "For every Months interval",
    //     MW: "",
    //     Margin: ""
    // },
    {
        Condition: "When Quarter is more than",
        MW: "",
        Margin: ""
    },
    // {
    //     Condition: "For every Quarter interval",
    //     MW: "",
    //     Margin: ""
    // },
    {
        Condition: "When seasons are more than",
        MW: "",
        Margin: ""
    },
    // {
    //     Condition: "For every seasons interval",
    //     MW: "",
    //     Margin: ""
    // },
    {
        Condition: "When Calendars are more than",
        MW: "",
        Margin: ""
    },
    // {
    //     Condition: "For every Calendars interval",
    //     MW: "",
    //     Margin: ""
    // }
]

export default HedgingMargin
import React from 'react';
import { Box, Typography } from '@mui/material';
import ChatOverlayButton from './ChatOverlayButton';
import ChatOverlayBox from './ChatOverlayBox';
import { Link, useLocation } from 'react-router-dom';
import useChatAuth from '@hooks/useChatAuth';
import { GAS_PATH, ELECTRICITY_PATH, COMMON_PATH } from '@utils';
import { useMediaQuery } from '@mui/material';

const ChatOverlay = () => {
    const location = useLocation();
    const [showChat, setShowChat] = React.useState(false);
    const { auth } = useChatAuth();

    const handleToggleShowChat = () => {
        setShowChat((prevState) => !prevState);
    };

    const [showOverlay, setShowOverlay] = React.useState(false);
    const isSmallerScreen = useMediaQuery('(max-width: 900px)');

    React.useEffect(() => {
        // Check if the pathname includes '/chat'
        if (location.pathname.includes('/chat')) {
            // If the screen is smaller, show the overlay
            if (isSmallerScreen) {
                setShowOverlay(true);
            } else {
                // Otherwise, hide the overlay
                setShowOverlay(false);
            }
        } else {
            // For all other paths, show the overlay
            setShowOverlay(true);
        }
    }, [location.pathname, isSmallerScreen]);

    if (!showOverlay) {
        return;
    }

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: '10px',
                zIndex: 1000
            }}
        >
            {showChat && (
                <>
                    {auth ? (
                        <ChatOverlayBox
                            handleToggleShowChat={handleToggleShowChat}
                        />
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '10px',
                                height: '400px',
                                width: '300px',
                                backgroundColor: '#fff',
                                borderRadius: '5px',
                                boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
                                overflow: 'hidden'
                            }}
                        >
                            <Box>
                                <Typography>You are not logged in</Typography>
                                <Typography
                                    component={Link}
                                        to={location.pathname.includes('gas') ? GAS_PATH.CHAT_LOGIN : location.pathname.includes('electricity') ? ELECTRICITY_PATH.CHAT_LOGIN : COMMON_PATH.CHAT_LOGIN}
                                    sx={{
                                        textAlign: 'center',
                                        color: '#27485D',
                                        fontWeight: 'bold',
                                        display: 'block'
                                    }}
                                >
                                    Login
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </>
            )}
            <ChatOverlayButton handleToggleShowChat={handleToggleShowChat} />
        </Box>
    );
};

export default ChatOverlay;

export const AppName = 'PriceBlick';

export const LoginTitle = 'Login';
export const ForgotPasswordTitle = 'Forgot Password';

// common
export const HomeTitle = 'Home';
export const ProfileTitle = 'Profile';
export const ChangePasswordTitle = 'Change Password';
export const UpdatePasswordTitle = 'Update Password';
export const UsersTitle = 'Users';
export const SettingsTitle = 'Settings';
export const ChatTitle = 'Chat';
export const ChatLoginTitle = 'Chat Login';
export const OrdersTitle = 'Orders';
export const CalendarTitle = 'Calendar';
export const HelpTitle = 'Help';
export const HedgingMarginTitle = 'Hedging Margin';

// gas 
export const GAS_THE_TITLE = 'THE';
export const GAS_TTF_TITLE = 'TTF';
export const GAS_PEG_TITLE = 'PEG';
export const ContractDetailsTitle = 'Contract Details';
export const MarketPlaceTitle = 'Market Place';
export const IntradayTitle = 'Intraday';
export const WatchlistTitle = 'Watchlist';
export const OverviewTitle = 'Overview';
export const NewsFeedTitle = 'News Feed';
export const PriceProfilingTitle = 'Price Profiling';
export const NotificationsTitle = 'Notifications';

// electricity
export const ELECTRICITY_EUR_TITLE = 'CHE EUR';
export const ELECTRICITY_CHF_TITLE = 'CH CHF';
export const DisclaimerTitle = 'Disclaimer';
// import React, { useEffect } from 'react'
// import { Box, List, ListItem, Typography } from '@mui/material'
// import { AppName, DisclaimerTitle } from '@utils'

// const Disclaimer = () => {
//     useEffect(() => {
//         document.title = `${AppName} | ${DisclaimerTitle}`
//     }, [])

//     return (
//         <Box bgcolor={'white'} p={2} pr={4} pt={4} pb={4} borderRadius={1} boxShadow={1} textAlign={'justify'}>
//             <Typography pl={2}>Nutzungsbedingungen und rechtliche Hinweise zur Online-Plattform Priceblick (Disclaimer)</Typography>
//             <List sx={{ listStyle: "decimal", pl: 4 }}>
//                 <Box>
//                     <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
//                         Anwendungsbereich
//                     </ListItem>
//                     <Typography className='pl-4'>
//                         Die folgenden Bestimmungen regeln die allgemeinen Bedingungen zur Nutzung der Online-Plattform "Priceblick".
//                         Mit Unterzeichnung der Nutzungsbedingungen bzw. der ersten Verwendung der Online-Plattform Priceblick" anerkennt der
//                         Nutzer die vorliegenden Nutzungsbedingungen und Haftungsausschlüsse ausdrücklich und vorbehaltlos. Die Nutzungsbedingungen
//                         gelten während der gesamten Nutzungsdauer. Diese beginnt mit der Errichtung des Zugangs und endet mit dessen Deaktivierung
//                     </Typography>
//                 </Box>
//                 <Box>
//                     <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
//                         Zugangsberechtigung zur Online-Plattform
//                     </ListItem>
//                     <Typography className='pl-4'>
//                         Für den Zugang zum Priceblick ist eine Legitimation über ein von CKW abgegebenes Log-In erforderlich. Das Log-In wird nur
//                         Geschäftskunden, das heisst juristischen Personen und deren Mitarbeitern gewährt. Um Zugang zum Priceblick zu erhalten,
//                         muss sich der Mitarbeiter des Kunden mit den individuell zugeteilten Log-In Daten anmelden. CKW kann die erforderlichen
//                         Log-In Daten jederzeit ergänzen oder ändern.
//                     </Typography>
//                 </Box>
//                 <Box>
//                     <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
//                         Pflichten des Kunden
//                     </ListItem>
//                     <Typography className='pl-4'>
//                         Der Kunde ist für die rechtmässige Verwendung des Log-Ins durch die berechtigten Mitarbeiter selbst verantwortlich.
//                         Der Kunde verpflichtet sich zur Mitwirkung an der Erfassung oder Mutation aller für den optimalen Betrieb von Priceblick
//                         notwendigen Daten und meldet Änderungen (insb. Nutzerverwaltung) rechtzeitig an CKW. Diese ist ausschliesslich befugt,
//                         die gemeldeten Daten administrativ einzupflegen. Die Log-In-Daten sind geheim zu halten und gegen missbräuchliche
//                         Verwendung und Verwendung durch Unbefugte zu schützen. Der Kunde trägt sämtliche Folgen, die sich aus der Verwendung seiner
//                         Identifikationsmerkmale ergeben. Der technische Zugang inklusive der dazu erforderlichen Hard- und Software ist Sache des Kunden.
//                         Die technischen Voraussetzungen können jederzeit ändern. Der Kunde ist dafür verantwortlich, dass die in seinem Einflussbereich
//                         liegende Hard- und Software dem sich ändernden technischen Umfeld angepasst werden.
//                     </Typography>
//                 </Box>
//                 <Box>
//                     <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
//                         Zustandekommen eines Handelsgeschäfts
//                     </ListItem>
//                     <Typography className='pl-4'>
//                         Der Kunde versendet über die Online-Plattform eine Anfrage, welche automatisiert bestätigt wird. Ein Geschäft gilt erst dann als
//                         bestätigt und zustande gekommen, wenn der Kunde eine manuelle Bestätigung des Händlers erhält. Bis zu diesem Zeitpunkt kann CKW
//                         das Geschäft jederzeit ablehnen.
//                     </Typography>
//                 </Box>
//                 <Box>
//                     <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
//                         Verfügbarkeit der Online-Plattform
//                     </ListItem>
//                     <Typography className='pl-4'>
//                         CKW übernimmt keinerlei Gewährleistung für die unterbrechungsfreie Funktionstüchtigkeit, Vollständigkeit, Richtigkeit
//                         und Zuverlässigkeit von Priceblick und der darin aufbereiteten Daten. Bei der Feststellung von Sicherheitsrisiken ist CKW zudem
//                         jederzeit berechtigt, den Zugang zu Priceblick zu unterbrechen oder vollständig einzustellen. Aus der rechtmässigen Unterbrechung
//                         oder Einstellung des Zugangs entsteht dem Nutzer kein Anspruch auf Entschädigung irgendwelcher Art.
//                     </Typography>
//                 </Box>
//                 <Box>
//                     <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
//                         Haftungsausschluss
//                     </ListItem>
//                     <Typography className='pl-4'>
//                         CKW haftet nicht für allfällige Schäden, welchem dem Nutzer durch unsachgemässe Benutzung von Priceblick und die Verletzung
//                         der vorgenannten (vgl. Ziff. 3) Pflichten, insbesondere der Verwendung von Priceblick durch Unberechtigte entstehen und behält sich vor,
//                         allfällige CKW entstandene Schäden gegenüber dem Kunden geltend zu machen. CKW übernimmt weiter keine Haftung für Schäden,
//                         welche dem Kunden aufgrund der Verwendung der in Priceblick zur Verfügung gestellten Daten oder Informationen entstanden sind.
//                     </Typography>
//                 </Box>
//                 <Box>
//                     <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
//                         Datenschutz
//                     </ListItem>
//                     <Typography className='pl-4'>
//                         Der Nutzer verpflichtet sich, über alle Tatsachen und Informationen Stillschweigen zu bewahren, die ausdrücklich als geschützt oder
//                         vertraulich oder in anderer Weise schriftlich gekennzeichnet worden sind oder die aus den Umständen ihrer Offenlegung nach Treu und
//                         Glauben als geschützt oder vertraulich angesehen werden müssen. Als vertraulich gelten insbesondere Struktur und Inhalt der im Rahmen
//                         von Priceblick angebotenen Dienstleistungen sowie sämtliche darüber dem Kunden zugänglich gemachten Marktinformationen
//                     </Typography>
//                     <List sx={{ listStyle: "lower-alpha", pl: 8 }}>
//                         <Box>
//                             <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
//                                 Automatisch gespeicherte Daten
//                             </ListItem>
//                             <Typography pl={2}>
//                                 Durch den Besuch von Priceblick werden automatisch Daten in Logfiles gespeichert. Namentlich sind dies die IP-Adresse des Kunden bzw.
//                                 die IP-Adresse seines Internet Service Providers, die Einstiegsseite (von welcher der Kunde auf das Serviceportal gestossen ist), der Browsertyp,
//                                 das Betriebssystem und die Plattform, Zugriffszeiten und Datum (nachfolgend «Verbindungsdaten»). Diese Verbindungsdaten sollen
//                                 Aufschluss darüber geben, wie Besucher Priceblick nutzen; sie werden für statistische Zwecke ausgewertet und für interne Massnahmen zur
//                                 Qualitätsverbesserung verwendet. Der Kunde bzw. der jeweilige Nutzer willigt ein, dass sie darüber hinaus verwendet werden können,
//                                 um ihm individualisierte Angebote und Produktinformationen zuzustellen. CKW verwendet so genannte Cookies, um die Qualität ihrer
//                                 Dienstleistungen zu verbessern und um besser verstehen zu können, wie die Besucher diese Website nutzen.
//                             </Typography>
//                         </Box>
//                         <Box>
//                             <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
//                                 Erhebung, Verarbeitung und Weitergabe von persönlichen Daten
//                             </ListItem>
//                             <Box pl={2}>
//                                 <Typography>
//                                     Beim Verwenden von Priceblick werden Informationen zur Person des Kunden an CKW übermittelt (wie z.B. Name, Kontaktdaten, E-Mail-Adresse).
//                                     Eine entsprechende Übermittlung erfolgt etwa durch das Registrieren beim Log-In. Mit der Übermittlung seiner Daten an CKW erklärt sich der
//                                     Kunde damit einverstanden, dass die Daten zum Zwecke des Betriebs von Priceblick von CKW sowie damit im Zusammenhang stehender
//                                     Dienstleistungen von CKW gespeichert und bearbeitet werden. Darüber hinaus willigt der Kunde bzw. der jeweilige Nutzer ein,
//                                     dass sie darüber hinaus verwendet werden können, um ihm individualisierte Angebote und Produktinformationen zuzustellen.
//                                 </Typography>
//                                 <Typography>
//                                     Die Datenbearbeitung erfolgt durch CKW oder durch die Priceblick Ltd (Hoster des Serviceportals). Die Datenübermittlung und Datenhaltung
//                                     erfolgt somit ausschliesslich in Ländern, die gemäss Liste des Eidgenössischen Datenschutzbeauftragten über
//                                     ein angemessenes Datenschutzniveau verfügen.
//                                 </Typography>
//                                 <Typography>
//                                     Die persönlichen Daten des Kunden werden weder an Dritte verkauft noch werden sie grundsätzlich mit Dritten ausserhalb der
//                                     CKW-Gruppe geteilt. Vorbehalten bleibt die Weitergabe der Daten durch CKW an externe Unternehmen oder Vertreter zur Erbringung
//                                     von Dienstleistungen für CKW und zu den oben erwähnten Zwecken. Diese Unternehmen und Vertreter sind verpflichtet, sich bei der
//                                     Nutzung der persönlichen Daten an die geltenden Gesetze und unsere Datenschutzbestimmungen zu halten. Im Weiteren wird CKW die
//                                     Personendaten gegenüber sonstigen Dritten nur bekannt geben, sofern und soweit dies nach geltendem Gesetz, gerichtlicher Verfügungen
//                                     oder behördlicher Regulierung vorgeschrieben ist.
//                                 </Typography>
//                             </Box>
//                         </Box>
//                         <Box>
//                             <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
//                                 Datenübertragung und -sicherheit
//                             </ListItem>
//                             <Typography pl={2}>
//                                 Der Kunde ist sich bewusst, dass das Internet ein offenes, für jedermann zugängliches Netz ist und im Allgemeinen nicht als sichere
//                                 Umgebung gilt. Demnach können die Personendaten des Kunden während der Übertragung über das Internet womöglich verloren gehen oder von
//                                 unberechtigten Dritten abgefangen und ohne seine Zustimmung gesammelt bzw. genutzt werden. Folglich kann dies dazu führen, dass Personendaten
//                                 offengelegt oder inhaltlich verändert werden. Zudem können über das Internet versendete Daten grenzüberschreitend, falls er dies aus
//                                 Gründen der Sicherheit für angemessen erachtet. Für die auf dem Priceblick gespeicherten Personendaten des Kunden gewährleistet CKW
//                                 eine den gesetzlichen Vorgaben entsprechende Datensicherheit.
//                             </Typography>
//                         </Box>
//                     </List>
//                 </Box>
//                 <Box>
//                     <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
//                         Vertraulichkeit
//                     </ListItem>
//                     <Typography className='pl-4'>
//                         CKW legt Wert auf den Schutz der Privatsphäre der Nutzer von Priceblick und verwendet deren persönliche Daten gemäss der
//                         jeweils geltenden Datenschutzgesetzgebung. Die nachfolgende Datenschutzerklärung gibt Auskunft darüber, wie CKW mit Personendaten
//                         der Nutzer umgeht, die beim Besuch von Priceblick erhoben werden.
//                     </Typography>
//                 </Box>
//                 <Box>
//                     <ListItem sx={{ display: "list-item", fontWeight: "bold" }}>
//                         Weitere Bestimmungen
//                     </ListItem>
//                     <Typography className='pl-4'>
//                         CKW behält sich vor, die vorliegenden Nutzungsbedingungen jederzeit anzupassen. Sie gelten ohne schriftlichen Widerspruch mit der
//                         nächsten Nutzung des Online-Portals als genehmigt. Ausschliesslicher Gerichtsstand für alle sich aus oder im Zusammenhang mit der
//                         Nutzung von Priceblick ergebenden Streitigkeiten ist Luzern.
//                     </Typography>
//                 </Box>
//             </List>
//             <Box pl={6} mt={2}>
//                 <Typography>Centralschweizerische Kraftwerke AG</Typography>
//                 <Typography>Luzern, Dezember 2017</Typography>
//             </Box>
//         </Box>
//     )
// }

// export default Disclaimer

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link, Outlet } from 'react-router-dom';

function samePageLinkNavigation(event) {
    if (
        event.defaultPrevented ||
        event.button !== 0 || // ignore everything but left-click
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
}

function LinkTab(props) {
    return (
        <Tab
            component={Link}
            aria-current={props.selected && 'page'}
            {...props}
        />
    );
}

LinkTab.propTypes = {
    selected: PropTypes.bool,
};

export default function NavTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        // event.type can be equal to focus with selectionFollowsFocus.
        if (
            event.type !== 'click' ||
            (event.type === 'click' && samePageLinkNavigation(event))
        ) {
            setValue(newValue);
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="nav tabs example"
                role="navigation"
            >
                <LinkTab label="Page One" to="/drafts" />
                <LinkTab label="Page Two" to="/trash" />
                <LinkTab label="Page Three" to="/spam" />
            </Tabs>
            <Outlet />
        </Box>
    );
}

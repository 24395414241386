import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Box, Typography, TextField } from '@mui/material';
import { AppName, LoginTitle } from '@utils';

import HeroBackgroudImage from '../assets/images/background.png';
import { loginPageData } from '../data';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Login from './Login';
import ForgotPassword from './ForgotPassword';

export default function LandingPage() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `${AppName} | ${LoginTitle}`;
    }, []);

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.slice(1));
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [location.hash]);

    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const handleShowForgotPassword = () => {
        setShowForgotPassword(true);
    };

    return (
        <Box sx={styles.root}>
            <Box sx={styles.topSection} id="home">
                <Box sx={styles.header}>
                    <Typography sx={styles.logo}>
                        Price<span style={{ color: '#2EA3F0' }}>Blick</span>
                    </Typography>
                    <Box sx={styles.headerLinks}>
                        {/* scroll down the page to respective section when click with id */}
                        <Typography
                            sx={{ cursor: 'pointer' }}
                            onClick={() => navigate('#home')}
                        >
                            Home
                        </Typography>
                        <Typography
                            sx={{ cursor: 'pointer' }}
                            onClick={() => navigate('#about')}
                        >
                            About
                        </Typography>
                        <Typography
                            sx={{ cursor: 'pointer' }}
                            onClick={() =>
                                window.open('https://docs.priceblick.com')
                            }
                        >
                            Docs
                        </Typography>
                        <Typography
                            sx={{ cursor: 'pointer' }}
                            onClick={() => navigate('#contact')}
                        >
                            Contact
                        </Typography>
                    </Box>
                </Box>
                <Box sx={styles.hero}>
                    <Typography sx={styles.heroText}>
                        Empowering growth with real data
                    </Typography>
                    <Box sx={styles.loginContainer}>
                        <Typography variant="h5" sx={{ textAlign: 'center' }}>
                            {showForgotPassword ? 'Forgot password' : 'Login'}
                        </Typography>
                        {showForgotPassword ? <ForgotPassword /> : <Login />}
                        <Box sx={{ mt: '20px' }}>
                            {showForgotPassword ? (
                                <Typography
                                    variant="body1"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => setShowForgotPassword(false)}
                                >
                                    Remember your password? Login
                                </Typography>
                            ) : (
                                <Typography
                                    variant="body1"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={handleShowForgotPassword}
                                >
                                    Forgot password?
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.midSection} id="about">
                <Typography sx={styles.midSectionHeading}>
                    Meet PriceBlick
                </Typography>
                <Box sx={styles.midSectionBox}>
                    {loginPageData.map((item) => (
                        <Box
                            key={item.id}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '20px',
                                padding: '50px 50px',
                                border: '2px solid #3dc3c1',
                                borderRadius: '5px',
                                background: '#E9F3F3',
                                width: '400px'
                            }}
                        >
                            <img
                                src={item.image}
                                alt={item.title}
                                style={{
                                    width: '144px',
                                    height: '140px'
                                }}
                            />
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: 'center',
                                    fontSize: '28px'
                                }}
                            >
                                {item.title}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: 'center',
                                    fontSize: '18px'
                                }}
                            >
                                {item.description}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box sx={styles.bottomSection} id="contact">
                <Typography sx={styles.midSectionHeading}>
                    GET IN TOUCH
                </Typography>
                <Box sx={styles.bottomSectionContainer}>
                    <Box>
                        <Box sx={{ display: 'flex', gap: '10px', mt: '20px' }}>
                            <EmailOutlinedIcon />
                            <Typography variant="body1">
                                info@setrading.ch
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '10px', mt: '20px' }}>
                            <LocationOnOutlinedIcon />
                            <Typography variant="body1">
                                United Kingdom
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '10px', mt: '20px' }}>
                            <LocalPhoneOutlinedIcon />
                            <Typography variant="body1">
                                +41 44 250 42 90
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px'
                        }}
                    >
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter your name"
                            sx={{
                                width: '500px',
                                backgroundColor: '#fff',
                                borderRadius: '5px'
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter your phone Number"
                            sx={{
                                width: '500px',
                                backgroundColor: '#fff',
                                borderRadius: '5px'
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            type="email"
                            placeholder="Enter valid email address"
                            sx={{
                                width: '500px',
                                backgroundColor: '#fff',
                                borderRadius: '5px'
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter your message"
                            multiline
                            rows={4}
                            sx={{
                                width: '500px',
                                backgroundColor: '#fff',
                                borderRadius: '5px'
                            }}
                        />
                        <Button
                            variant="contained"
                            sx={{
                                width: '200px',
                                backgroundColor: 'light-blue',
                                '&:active': {
                                    backgroundColor: 'light-blue'
                                },
                                '&:hover': {
                                    backgroundColor: 'light-blue'
                                }
                            }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

const styles = {
    root: {
        height: '100%'
    },
    topSection: {
        backgroundImage: `url(${HeroBackgroudImage})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
        px: '70px',
        py: '10px'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: 'white',
        px: '150px'
    },
    logo: {
        borderRight: '3px solid #fff',
        fontSize: '2rem',
        fontWeight: 'bold',
        color: 'white',
        textDecoration: 'none',
        corsor: 'pointer',
        paddingRight: '20px'
    },
    headerLinks: {
        display: 'flex',
        gap: '20px'
    },
    hero: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 'calc(100% - 250px)',
        px: '150px'
    },
    heroText: {
        color: '#fff',
        fontSize: '50px',
        textTransform: 'uppercase',
        fontWeight: 500,
        lineHeight: '66px',
        width: '35%'
    },
    loginContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '400px',
        border: '1px solid #ccc',
        padding: '30px 40px',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        marginTop: '100px'
    },
    icon: {
        color: '#fff',
        backgroundColor: '#F50057',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px'
    },
    button: {
        fontWeight: 'bold',
        marginTop: '20px',
        backgroundColor: 'light-blue',
        height: '50px',
        '&:active': {
            backgroundColor: 'light-blue'
        },
        '&:hover': {
            backgroundColor: 'light-blue'
        }
    },
    midSection: {
        padding: '0 70px 50px',
        px: '150px'
    },
    midSectionHeading: {
        fontSize: '48px',
        textAlign: 'center',
        marginBottom: '70px'
    },
    midSectionBox: {
        display: 'flex',
        justifyContent: 'space-around',
        gap: '20px'
    },
    bottomSection: {
        background: 'linear-gradient(88.89deg, #3B9D9D 0%, #27485D 83.49%)',
        px: '150px',
        py: '50px',
        color: '#fff'
    },
    bottomSectionContainer: {
        display: 'flex',
        justifyContent: 'space-around'
    }
};

import React, { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TickMemberCard } from '../chat/components/TickMemberCard';
import {
    useGetChatProfileQuery,
    useGetOrSearchAllUsersQuery,
    useCreateGroupMutation
} from '@store/feature/chat/chatApi';
import { Box, TextField, InputAdornment, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import UserAvatar from '../chat/components/UserAvatar';
// import Snackbar from '@mui/material/Snackbar';
// import Alert from '@mui/material/Alert';

import { GAS_PATH, ELECTRICITY_PATH, COMMON_PATH } from '@utils';

const NextPage = ({ hideForm, formData }) => {
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width: 1080px)');
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const { data: profile } = useGetChatProfileQuery();
    const [createGroup] = useCreateGroupMutation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab') || 'all';
    const searchRef = useRef(null);
    const searchTimeout = 500;
    const [searchText, setSearchText] = React.useState('');
    const { data: usersData } = useGetOrSearchAllUsersQuery(searchText);

    const handleChange = (e) => {
        e.preventDefault();
        const text = e.target.value;

        if (searchRef.current) {
            clearTimeout(searchRef.current);
        }

        searchRef.current = setTimeout(() => {
            setSearchText(text);
        }, searchTimeout);
    };

    const filteredUsers = usersData?.filter(
        (user) => user?.user?.uuid !== profile?.uuid
    );

    const handleCheckboxChange = (userId, isChecked) => {
        setSelectedUserIds((prevSelectedUsers) => {
            if (isChecked) {
                return [...prevSelectedUsers, userId];
            } else {
                return prevSelectedUsers.filter((id) => id !== userId);
            }
        });
    };

    const handleCreateGroup = async () => {
        try {
            const data = {
                name: formData.name,
                members: selectedUserIds
            };

            const response = await createGroup(data);
            hideForm();
            navigate(
                `${location.pathname.includes('gas')
                    ? `${GAS_PATH.CHAT}/group/${response.data.uuid}?tab=${tab}`
                    : location.pathname.includes('electricity')
                        ? `${ELECTRICITY_PATH.CHAT}/group/${response.data.uuid}?tab=${tab}`
                        : `${COMMON_PATH.CHAT}/group/${response.data.uuid}?tab=${tab}`
                }`
            );
        } catch (error) {
            alert('ENTER GROUP NAME. GROUP NOT CREATED');
        }
    };

    const [selectedUsers, setSelectedUsers] = useState([]);

    React.useEffect(() => {
        const updatedSelectedUsers = filteredUsers?.filter((user) =>
            selectedUserIds.includes(user?.user?.uuid)
        );
        setSelectedUsers(updatedSelectedUsers);
    }, [selectedUserIds, filteredUsers]);

    const handleRemoveUser = (userId) => {
        setSelectedUserIds((prevSelectedUsers) =>
            prevSelectedUsers.filter((id) => id !== userId)
        );
    };

    return (
        <>
            <div className="flex flex-col justify-between gap-2">
                <div
                    style={{
                        height: 'auto',
                        borderRadius: '16px',
                        position: 'sticky',
                        top: '0'
                    }}
                >
                    <Box
                        sx={{
                            px: isSmallScreen ? '8px' : '16px',
                            position: 'sticky',
                            top: '-16px',
                            pb: '8px',
                            width: isSmallScreen ? '90%' : '100%',
                            backgroundColor: 'white',
                            zIndex: '100',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div className={isSmallScreen && 'flex justify-start'}>
                            <span
                                className={`${isSmallScreen ? 'text-sm pl-3' : 'text-lg '
                                    } font-semibold bg-white z-100 `}
                            >
                                <span>Select </span>
                                <span>Members</span>
                            </span>
                        </div>

                        <div className="flex gap-2 ml-2 pb-6 flex-wrap px-2 max-h-[300px] overflow-y-auto">
                            {selectedUsers?.map((user, index) => (
                                <div
                                    key={index}
                                    style={{
                                        position: 'relative',
                                        display: 'inline-block'
                                    }}
                                    title={user?.user?.fullname}
                                >
                                    {/* Cross button for removing the user */}
                                    <button
                                        onClick={() =>
                                            handleRemoveUser(user?.user?.uuid)
                                        }
                                        style={{
                                            position: 'absolute',
                                            top: '-2px',
                                            right: '-1px',
                                            background: 'white',
                                            border: '1px solid gray', // updated to give a red border
                                            color: '#60c4f2',
                                            height: '16px', // Increased height for better visibility
                                            width: '16px', // Increased width for better visibility
                                            borderRadius: '50%', // Make the button round
                                            cursor: 'pointer',
                                            fontWeight: 'normal',
                                            fontSize: '18px',
                                            padding: '2px',
                                            zIndex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            lineHeight: 0 // Reduce line height to center the text vertically
                                        }}
                                    >
                                        &times;{' '}
                                        {/* Unicode character for a multiplication sign (×), which appears as a cross */}
                                    </button>
                                    {/* UserAvatar component */}

                                    <UserAvatar
                                        sx={{ width: '50px', height: '50px', }}
                                        name={user?.user?.fullname}
                                        imgSource={user?.user?.image_url}
                                        isActive={
                                            user?.user?.uuid ===
                                            profile?.uuid ||
                                            user?.user?.status === 'active'
                                        }
                                    />
                                </div>
                            ))}
                        </div>

                        {!isSmallScreen && (
                            <TextField
                                label="Search by name"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={handleChange}
                            />
                        )}
                        {isSmallScreen && (
                            <TextField
                                label="Search"
                                size="small"
                                onChange={handleChange}
                                sx={{
                                    fontSize: '12px',
                                    width: '100%'
                                }}
                            />
                        )}
                    </Box>

                    <Box
                        sx={{
                            maxHeight: '275px',
                            overflowY: 'auto',
                            backgroundColor: 'white',
                            border: '1px solid', // Set the border width
                            borderImage:
                                'linear-gradient(to right, lightgray, white) 1', // scrollbarWidth: 'thick', // For Firefox
                            '&::-webkit-scrollbar': {
                                width: '6px'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'black',
                                borderRadius: '4px'
                            },
                            '&': {
                                scrollbarWidth: 'thick',
                                scrollbarColor: 'gray transparent'
                            }
                        }}
                    >
                        {filteredUsers?.map((u, index) => {
                            return (
                                <TickMemberCard
                                    key={index}
                                    imageURL={u?.user?.image_url}
                                    name={u?.user?.fullname}
                                    userId={u?.user?.uuid}
                                    isActive={
                                        u?.user?.uuid === profile?.uuid ||
                                        u?.user?.status === 'active'
                                    }
                                    isChecked={selectedUserIds.includes(
                                        u?.user?.uuid
                                    )}
                                    onCheckboxChange={handleCheckboxChange}
                                />
                            );
                        })}
                    </Box>
                </div>

                <div
                    className={`flex  justify-between gap-1 mt-4 mx-4 items-center ${isSmallScreen ? 'flex-col' : 'flex-row'
                        }
                    `}
                >
                    <button
                        type="button"
                        onClick={hideForm}
                        className={`bg-[#A6382A] text-white text-sm px-0 py-1 rounded-md shadow-sm mt-1 ${isSmallScreen && 'pr-0'
                            }`}
                        style={{ width: '60%' }}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={handleCreateGroup}
                        className={`bg-[#2FACFE] text-white text-sm px-0 py-1 rounded-md shadow-sm mt-1 ${isSmallScreen && 'pr-0'
                            }`}
                        style={{ width: '60%' }}
                    >
                        Create Group
                    </button>
                </div>
            </div>
        </>
    );
};

export default NextPage;
